import axios from 'axios';
 
const getEmail = () => {
    const email = sessionStorage.getItem('email');
    return { email };
};

axios.interceptors.request.use(
    (config) => {
        const { email } = getEmail();
        if (email) {
            config.headers['email'] = email;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
 
export default axios;
 
 