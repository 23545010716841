import React, { useState, useRef, useEffect } from "react";
import axios from "../caller/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Bussinesscentral = ({ databases, setDatabases }) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // For company name input
    const [folderName, setFolderName] = useState(''); // For folder name input after company is fetched
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tableNames, setTableNames] = useState([]);  // State to hold table names
    const [selectedTables, setSelectedTables] = useState([]); // State for selected table names
    const [isCompanyFetched, setIsCompanyFetched] = useState(false); // New state to track if company data is fetched

    // Get user email from sessionStorage
    const companyName = searchTerm; // Assuming company name is entered here.
    const userEmail = getUserEmail();
    if (userEmail) {
        console.log(`User's email: ${userEmail}`);
    } else {
        console.log("Email not found in session storage.");
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const fetchDatabases = async () => {
        const email = getUserEmail(); // Get user email
        if (!email) {
            console.log("User email not found.");
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/databases`,
                { email }
            );
            console.log("response krao from buss", response);

            const fetchedDatabases = response.data.databases;
            setDatabases(fetchedDatabases); // Setting databases after fetch
        } catch (err) {
            console.error("Error fetching databases:", err);
        }
    };

    useEffect(() => {
        fetchDatabases();
    }, []);

    // Hook to handle window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getUserEmail() {
        const keys = Object.keys(sessionStorage);
        for (const key of keys) {
            if (key.includes('login.windows.net')) {
                const data = JSON.parse(sessionStorage.getItem(key));
                if (data && data.username) {
                    return data.username;
                }
            }
        }
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!searchTerm && !folderName) {
            alert("Please enter a company name or folder name.");
            return;
        }

        setLoading(true); // Show loading animation

        try {
            // Send POST request to backend
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/business_central/login`,
                new URLSearchParams({
                    company_name: searchTerm,
                    folder_name: folderName, // Add folder name to the request
                })
            );

            setLoading(false);

            // Check for success response
            if (response.status === 200) {
                toast.success("Data fetched successfully!");
                setTableNames(response.data.table_names); // Set table names in state
                setIsCompanyFetched(true); // Mark company as fetched
                console.log("Response from backend:", response.data);
            } else {
                toast.error("Unable to fetch data. Please try again.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error submitting form:", error);
            toast.error("Unable to fetch data. Please try again.");
        }
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedTables(prev => [...prev, value]);
        } else {
            setSelectedTables(prev => prev.filter(table => table !== value));
        }
    };

    const handleUpload = async () => {
        if (selectedTables.length === 0 || !folderName || !companyName || !userEmail) {
            toast.error("Please fill all the fields and select tables.");
            return;
        }

        setLoading(true); // Show loading animation

        try {
            // Prepare form data
            const formData = new FormData();
            formData.append("folder_name", folderName);
            formData.append("user_input", selectedTables.join(','));
            formData.append("email_id", userEmail);
            formData.append("company_name", companyName);

            console.log("folder_name",folderName)
            console.log("user_input",selectedTables.join(','))
            console.log("email_id",userEmail)
            console.log("company_name",companyName)

            // Send POST request to the backend route
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/business_central/create_database`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set header for FormData
                }
            });

            setLoading(false);

            // Check if the request was successful
            if (response.status === 200) {
                toast.success(response.data.message || "Database created successfully!");
                setFolderName('');
                setSelectedTables([]);
                fetchDatabases();
            } else {
                toast.error("An error occurred while creating the database.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error uploading:", error);
            toast.error("Unable to upload data. Please try again.");
        }
    };

    return (
        <div className={` flex flex-col justify-center  py-2 px-6 `}>
            <div className={`w-full flex flex-col justify-center items-center ${windowWidth <= 640 ? 'mt-[1rem]' : ''}`}>
                <div className="flex w-full flex-col justify-center items-center mb-6">
                    <h2 className="text-lg font-semibold mb-2 sm:text-2xl sm:font-semibold sm:mb-4 md:text-2xl md:font-semibold md:mb-4 lg:text-2xl lg:font-semibold lg:mb-4 xl:text-2xl xl:font-semibold xl:mb-4">
                        Create Database
                    </h2>
                    <div className="w-full flex flex-col justify-center items-center">
                        <form className="w-full md:w-[70%] lg:w-[70%] xl:w-[80%] flex flex-col" onSubmit={handleSubmit}>
                            {/* Company name input (shown only if company is not fetched yet) */}
                            {!isCompanyFetched && (
                                <input
                                    type="text"
                                    name="hostName"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    required
                                    placeholder="Enter your company name..."
                                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6 focus:outline-none px-2"
                                />
                            )}
                            {isCompanyFetched && (
                                <input
                                    type="text"
                                    name="folderName"
                                    value={folderName}
                                    onChange={(e) => setFolderName(e.target.value)}
                                    required
                                    placeholder="Enter your folder name..."
                                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6 focus:outline-none px-2"
                                />
                            )}

                            {/* Submit button */}
                            <div className="flex justify-center">
                                {/* <button
                                    type="submit"
                                    className="w-[95%] md:w-[45%] lg:w-[45%] xl:w-[45%] rounded-full bg-[#2E5BFF] text-white font-semibold py-3 hover:bg-[#335fff] transition"
                                >
                                    Submit
                                </button> */}
                                {/* <div></div> */}
                                {!isCompanyFetched ? (
                                    <button
                                        type="submit"
                                        className=" w-[95%] md:w-[45%] lg:w-[45%] xl:w-[45%] rounded-full bg-[#2E5BFF] text-white font-semibold py-3 hover:bg-[#335fff] transition"
                                    >
                                        Submit
                                    </button>
                                ) : (
                                    <button
                                        type="button" // Set type to button so it doesn't trigger the form submission
                                        className="w-[95%] md:w-[45%] lg:w-[45%] xl:w-[45%] rounded-full bg-[#2E5BFF] text-white font-semibold py-3 hover:bg-[#335fff] transition"
                                        onClick={handleUpload} // Handle the upload action here
                                    >
                                        Upload
                                    </button>
                                )}
                            </div>
                        </form>
                        {isLoading && (
                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
                                <dotlottie-player
                                    src="https://lottie.host/1839b101-005a-4014-bb66-2af42bf8a9b1/moiTOzQErw.json"
                                    background="transparent"
                                    border="2px solid black"
                                    speed="1"
                                    loop
                                    autoplay
                                    style={{ width: '40%', height: '40%' }}
                                ></dotlottie-player>
                            </div>
                        )}

                        {/* Display table names with checkboxes */}
                        {tableNames.length > 0 && (
                            <div className="m-2 w-full">
                                <h2 className="text-left font-semibold text-lg mb-4 mt-8 sm:mt-1 md:mt-1 lg:mt-1 xl:mt-1">Select Table </h2>
                                <div className="text-center mb-1 max-h-36 sm:max-h-45 md:max-h-54 lg:max-h-64 xl:max-h-64 w-[85%] sm:w-full md:w-full lg:w-full overflow-y-auto scrollbar-hidden">

                                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:grid-cols-2 xl:gap-4">
                                        {tableNames.map((table, index) => (
                                            <label key={table} className={`flex items-center`}>
                                                <input
                                                    type="checkbox"
                                                    value={table}
                                                    onChange={handleCheckboxChange}
                                                    className="mr-2"
                                                />
                                                {table}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        )}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bussinesscentral;
