import React, {useState, useEffect} from 'react';
import '../Styles/Hero.css';
import clogo from '../assets/clogo.png';
import img2 from '../assets/meridian_logo.png';
import Heroearthimg from '../assets/Heroearthimg.png';
import contactus from "../assets/contactus.png";
import ContactUsForm from './ContactUsForm'; // Import the ContactUsForm component
import { useNavigate } from "react-router-dom";
import axios from "../caller/axiosConfig";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig"; // Import your MSAL login configuration
import herobg3 from "../assets/herobg3.mp4";


function Hero() {
    const navigate = useNavigate();
    const { instance } = useMsal(); // Use MSAL instance for authentication
    const [showForm, setShowForm] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);


    const toggleForm = () => {
        setShowForm(!showForm);
    };

    useEffect(() => {
      // Trigger the video animation
      setIsLoaded(true);
  
      // Start the content animation after the video animation completes
      const contentAnimationTimeout = setTimeout(() => {
        setContentLoaded(true);
      }, 1500); // Match the video animation duration (1.5s)
  
      return () => clearTimeout(contentAnimationTimeout);
    }, []);

    function getUserEmail() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);
    
        // Loop through each key to find the relevant data
        for (const key of keys) {
          // Check if the key indicates it may contain user info, particularly the name
          if (key.includes('login.windows.net')) { // or any other relevant keyword
            // Parse the JSON string to get the data object
            const data = JSON.parse(sessionStorage.getItem(key));
            // Check if the data object contains a name field
            if (data && data.username) {
              return data.username; // Return the user's name
            }
          }
        }
        return null; // Return null if no name is found
      }
    
      // Usage
      // const userEmail = getUserEmail();
      // if (userEmail) {
      //   console.log(`User's email: ${userEmail}`);
      // } else {
      //   console.log("Email not found in session storage.");
      // }
    
      // Function to get the user's name from session storage
      function getUserName() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);
    
        // Loop through each key to find the relevant data
        for (const key of keys) {
          // Check if the key indicates it may contain user info, particularly the name
          if (key.includes('login.windows.net')) { // or any other relevant keyword
            // Parse the JSON string to get the data object
            const data = JSON.parse(sessionStorage.getItem(key));
            // Check if the data object contains a name field
            if (data && data.name) {
              return data.name; // Return the user's name
            }
          }
        }
        return null; // Return null if no name is found
      }
    
      // Usage
      
    
      const handleClick = () => {
        navigate('/login')
        // if (!userName || !userEmail) {
        //   console.error("User information is missing.");
        //   return;
        // }
    
        // Start login process using MSAL
        // instance.loginPopup(loginRequest)
        //   .then((response) => {
        //     console.log("Login successful:", response);
        //     // Optionally, you can handle any post-login logic here, like storing the access token or user info
        //     sessionStorage.setItem('msalToken', response.accessToken);
        //     let userName = getUserName();
        //     if (userName) {
        //       console.log(`User's name: ${userName}`);
        //     } else {
        //       console.log("Name not found in session storage.");
        //     }
        //     // getUserName();
        //     let userEmail = getUserEmail();
        //     sessionStorage.setItem('email', userEmail);
        //     if (userEmail) {
        //       console.log(`User's email: ${userEmail}`);
        //     } else {
        //       console.log("Email not found in session storage.");
        //     }
        //     // getUserEmail();
        //      axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
        //     name: userName,
        //     email: userEmail,
        //   })
        //     .then((response) => {
        //       console.log("API response:", response.data);
              
        //     })
        //     .catch((error) => {
        //       console.error("Error in API call:", error);
        //     });
        //     navigate('/home');
        //   })
        //   .catch((error) => {
        //     console.error("Login failed:", error);
        //   });
      };
    
      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };
    

    return (
        <div className='hero' id='home'>
          <div
        className={`herovideo_section ${isLoaded ? "zoom-in-animation" : ""}`}
      >
        {/* Video Background */}
        <video autoPlay loop muted className="video-bg">
          <source src={`https://aisa0101.blob.core.windows.net/aibot/herobg3.mp4`} type="video/mp4" />
        </video>
      </div>
            <div className={`content ${contentLoaded ? "animate-content" : ""}`}>
                <div className="motto">
                   {/* <img 
                   src={img2} 
                   alt="Meridian Solution" 
                   loading="lazy" 
                   /> */}
                    <h1 className='font-bold text-[2rem]'>DataDiscover</h1>
                    <p className='font-sm'>Empower your business with cutting-edge AI that delivers fast, accurate insights and seamless integration into your existing systems.</p>
                    <div className="btn-section">
                         {/* <a href="https://datadiscover.ai" target="_blank" rel="noopener noreferrer"> */}
                            <button className="btnn-hero hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" id="getstartedbtn" onClick={handleClick}>Get Started</button>
                        {/* </a> */}
                        {/* <div className=''> */}
                            <button id="cntbtn" className="btnn-hero  contact-btn py-3 px-6 mx-3 hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" onClick={toggleForm}>
                                Contact Us
                            <img src={contactus} alt="Contact Us Icon" className="contact-icon" />
                            </button>
                        {/* </div>     */}
                        
                    </div>
                         
                </div>
            </div>
            {/* <div className="image-container">
                <img src={Heroearthimg} alt="Earth" className="rotating-image" />
            </div> */}
            {showForm && (
                <ContactUsForm onClose={toggleForm} />
            )}
        </div>
    );
}

export default Hero;

