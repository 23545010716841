import React, { useEffect, useRef } from 'react';
import '../Styles/AboutSection.css';
import AboutDataDiscovery from '../assets/AboutDataDiscovery.mp4';
import Simplifiescw from "../assets/Simplifiescw.png";
import Multidocument from "../assets/Multidocument.png";
import Empoweringbi from '../assets/Empoweringbi.png';
import DocumentSearch from '../assets/DocumentSearch.png';
import Realtime from '../assets/Realtime.png';
import Seamlessdi from '../assets/Seamlessdi.png';

const AboutSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const items = document.querySelectorAll(".section_title_all, .contents_boxes, .video_content");
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        }
      });
    }, {
      threshold: 0.3, // Trigger when 30% of the element is in view
    });
  
    items.forEach((item) => observer.observe(item));
  
    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <section className="section_all" id="services">
      <div className="About-container">
        {/* First Block */}
        <div className="section_title_all">
          <h3 className="section_title">About DataDiscover.ai</h3>
          <p className='sub'>Unlock Insights Hidden in Your Data – Powered by Advanced AI</p>
          <p className="content_subtitle">
            DataDiscover.ai is an advanced AI-driven business intelligence platform that swiftly processes and analyzes both structured and unstructured data, transforming it into actionable insights. With seamless integration into existing systems, intuitive natural language queries, and support for diverse data formats, it empowers businesses to make informed, data-driven decisions effortlessly.
          </p>
        </div>

        {/* Second Block */}
        <div className="content_section">
          {/* Left Column */}
          <div className="left_content">
            <div className="contents_boxes hidden21">
              <img src={Simplifiescw} alt="Simplifies Complex workflows" className='iconsimg' />
              <h5 className="abouts_title">Simplifies Complex workflows</h5>
            </div>
            <div className="contents_boxes hidden21">
              <img src={Multidocument} alt="Multi-document Querying" className='iconsimg'/>
              <h5 className="abouts_title">Multi-document Querying</h5>
            </div>
            <div className="contents_boxes hidden21">
              <img src={Empoweringbi} alt="Empowering Business Intelligence" className='iconsimg' />
              <h5 className="abouts_title ">Empowering Business Intelligence</h5>
            </div>
          </div>

          {/* Center Column with Video */}
          <div className="center_content">
            <video autoPlay loop muted className="video_content">
              <source src={`https://aisa0101.blob.core.windows.net/aibot/AboutDataDiscovery.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Right Column */}
          <div className="right_content">
            <div className="contents_boxes hidden21">
              <img src={Seamlessdi} alt="Seamless Data Integration" className='iconsimg' />
              <h5 className="abouts_title">Seamless Data Integration</h5>
            </div>
            <div className="contents_boxes hidden21">
              <img src={Realtime} alt="Real-time Analysis" className='iconsimg' />
              <h5 className="abouts_title">Real-time Analysis</h5>
            </div>
            <div className="contents_boxes hidden21">
              <img src={DocumentSearch} alt="AI-Powered Document Search" className='iconsimg' />
              <h5 className="abouts_title">AI-Powered Document Search</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
