import React, { useEffect, useState } from 'react';
import '../Styles/LoadingScreen.css';
import Heroearthimg from '../assets/Heroearthimg.png';
import stage2 from "../assets/stage2.png";

const LoadingScreen = ({ onComplete }) => {
    const [stage, setStage] = useState(1);
    const [marginTop, setMarginTop] = useState('25rem'); // Initially set to 25rem for Stage 2
    const [rotate, setRotate] = useState(0); // Rotation for Stage 2 (will be 180 degrees)

    useEffect(() => {
        const timers = [];

        // Stage 2 after 3 seconds, apply margin-top of 1rem and start rotation to 180 degrees
        timers.push(setTimeout(() => {
            setStage(2);
            setMarginTop('1rem'); // Set margin-top for Stage 2 (move up)
            setRotate(180); // Start rotation to 180 degrees
        }, 3000));

        // Stage 3 after 7 seconds, change margin-top to 0rem and rotate the image to 360 degrees
        timers.push(setTimeout(() => {
            setStage(3); // Move to Stage 3
            setMarginTop('-5rem'); // Change margin-top for Stage 3 (further move up)
            setRotate(360); // Complete rotation to 360 degrees
        }, 7000));

        // Complete the animation after 12 seconds
        timers.push(setTimeout(() => onComplete(), 12000));

        return () => timers.forEach(timer => clearTimeout(timer)); // Cleanup timers
    }, [onComplete]);

    return (
        <div className="loading-screen">
            {stage === 1 && (
                <div className="loading-stage1 animate__animated animate__fadeIn">
                    <h1 className="loading-title">DataDiscover.ai</h1>
                </div>
            )}
            {stage === 2 && (
                <div className="loading-subtitle">
                    <p className="animated-text animate__animated animate__fadeIn">Revolutionize Business Intelligence</p>
                    <img 
                        src={stage2} 
                        alt="Stage 2" 
                        className="stage2-image" 
                        style={{
                            marginTop, 
                            transform: `rotate(${rotate}deg)`, // Apply margin-top and rotation
                            transition: 'transform 3s ease-out, margin-top 3s ease-out' // Smooth transition for both
                        }}
                    />
                </div>
            )}
            {stage === 3 && (
                <div className="loading-final-stage">
                    <p className="loading-tagline">AI-Powered Solutions</p>
                    <img 
                        src={Heroearthimg} 
                        alt="Stage 3" 
                        className="stage2-image" 
                        style={{
                            marginTop, 
                            transform: `rotate(${rotate}deg)`, // Apply margin-top and rotation
                            transition: 'transform 3s ease-out, margin-top 3s ease-out' // Smooth transition for both
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default LoadingScreen;
