import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../caller/axiosConfig";
import { useMsal } from "@azure/msal-react";
import ContactUsForm from "./ContactUsForm"; // Import ContactUsForm
 // Import useMsal from MSAL React
import {
  FaMapMarkerAlt,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaBars,
  FaTimes,
  FaYoutube,
} from "react-icons/fa";
import "../Styles/Navbar.css";
import { loginRequest } from "../authConfig"; // Import your MSAL login configuration

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { instance } = useMsal(); // Use MSAL instance for authentication
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State for controlling the pop-up visibility
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  function getUserEmail() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
      // Check if the key indicates it may contain user info, particularly the name
      if (key.includes('login.windows.net')) { // or any other relevant keyword
        // Parse the JSON string to get the data object
        const data = JSON.parse(sessionStorage.getItem(key));
        // Check if the data object contains a name field
        if (data && data.username) {
          return data.username; // Return the user's name
        }
      }
    }
    return null; // Return null if no name is found
  }

  // Usage
  // const userEmail = getUserEmail();
  // if (userEmail) {
  //   console.log(`User's email: ${userEmail}`);
  // } else {
  //   console.log("Email not found in session storage.");
  // }

  // Function to get the user's name from session storage
  function getUserName() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
      // Check if the key indicates it may contain user info, particularly the name
      if (key.includes('login.windows.net')) { // or any other relevant keyword
        // Parse the JSON string to get the data object
        const data = JSON.parse(sessionStorage.getItem(key));
        // Check if the data object contains a name field
        if (data && data.name) {
          return data.name; // Return the user's name
        }
      }
    }
    return null; // Return null if no name is found
  }

  // Usage
  

  const handleClick = () => {

    // if (!userName || !userEmail) {
    //   console.error("User information is missing.");
    //   return;
    // }

    // Start login process using MSAL
    // instance.loginPopup(loginRequest)
    //   .then((response) => {
    //     console.log("Login successful:", response);
    //     // Optionally, you can handle any post-login logic here, like storing the access token or user info
    //     sessionStorage.setItem('msalToken', response.accessToken);
    //     let userName = getUserName();
    //     if (userName) {
    //       console.log(`User's name: ${userName}`);
    //     } else {
    //       console.log("Name not found in session storage.");
    //     }
    //     // getUserName();
    //     let userEmail = getUserEmail();
    //     sessionStorage.setItem('email', userEmail);
    //     if (userEmail) {
    //       console.log(`User's email: ${userEmail}`);
    //     } else {
    //       console.log("Email not found in session storage.");
    //     }
    //     // getUserEmail();
    //      axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
    //     name: userName,
    //     email: userEmail,
    //   })
    //     .then((response) => {
    //       console.log("API response:", response.data);
          
    //     })
    //     .catch((error) => {
    //       console.error("Error in API call:", error);
    //     });
    //     navigate('/home');
    //   })
    //   .catch((error) => {
    //     console.error("Login failed:", error);
    //   });
    navigate('/login')
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleContactForm = () => {
    setIsContactFormOpen(!isContactFormOpen);
  };

  return (
    <header>
      <nav className={`main-nav ${isLoaded ? "loaded" : ""}`}>
        <div className="logo">
          <a href="https://datadiscover.ai/" rel="noopener noreferrer">
          <p className='custom-gradienttext'>DataDiscover.ai</p>
          </a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
          <li><a href="#services">Services</a></li>
          <li><a href="#how-it-works">How it works</a></li>
          <li><a href="#top-features">Top Features</a></li>
          <li><a href="#faq" >FAQ</a></li>
        </ul>
        <button className="get-started-btn hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" onClick={handleClick}>
          Try Now
        </button>
      </nav>
      {/* {isContactFormOpen && <ContactUsForm onClose={toggleContactForm} />} */}

    </header>
  );
};

export default Navbar;
