import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Options from './Options';
import bgvdo1 from '../assets/bgvdo1.mp4'
import bgvdo2 from '../assets/bgvdo2.mp4'


function Home({ databases, setDatabases }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Trigger the video animation
    setIsLoaded(true);

    // Start the content animation after the video animation completes
    const contentAnimationTimeout = setTimeout(() => {
      setContentLoaded(true);
    }, 1500); // Match the video animation duration (1.5s)

    return () => clearTimeout(contentAnimationTimeout);
  }, []);

  return (
    <div className="relative w-full h-screen">
      {/* Video Section - Background */}
      <div
        className={`herovideo_section2 ${isLoaded ? "zoom-in-animation" : ""} absolute bottom-0 left-0 w-full h-auto z-0`}
      >
        <video autoPlay muted loop className="background_video w-full h-auto object-cover">
          <source src={`https://aisa0101.blob.core.windows.net/aibot/bgvdo1.mp4`} type="video/mp4" />
        </video>
      </div>

      {/* Content Section */}
      <div className="absolute top-0 left-0 w-full h-full z-10 flex flex-col items-center justify-start">
        <Navbar databases={databases} setDatabases={setDatabases} />
        <Options databases={databases} setDatabases={setDatabases} />
      </div>
    </div>
  );
}

export default Home;
