import React, { useEffect, useRef } from "react";
import "../Styles/TopFeatures.css";
import HighAccuracy from '../assets/HighAccuracy.png';
import Seamlessdi from '../assets/Seamlessdi.png';
import CustomIntegration from '../assets/CustomIntegration.png';
import PoweredAutomation from '../assets/PoweredAutomation.png';
import SecureCompliant from '../assets/SecureCompliant.png';

const TopFeatures = () => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const section = sectionRef.current;
            if (section) {
                const heading = section.querySelector(".top_features_main_heading");
                
                // Animate Heading
                const headingRect = heading.getBoundingClientRect();
                if (headingRect.top < window.innerHeight * 0.8) {
                    heading.classList.add("animate");
                }
                
                const boxes = section.querySelectorAll(".top_features_step_box");
                boxes.forEach(box => {
                    const rect = box.getBoundingClientRect();
                    if (rect.top < window.innerHeight * 0.8) {
                        box.classList.add("animate");
                    }
                });
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="top_features" id="top-features" ref={sectionRef}>
            <div className="top_features_text_section">
                <h2 className="top_features_main_heading">Top Features</h2>

                {/* First Row with Three Boxes */}
                <div className="top_features_grid first_row">
                    <div className="top_features_step_box">
                        <img src={HighAccuracy} alt="High Accuracy" className="top_features_iconsimg" />
                        <h3 className="top_features_step_title">1. High Accuracy:</h3>
                        <p className="top_features_step_description">
                            Provides up to 95% accurate insights, ensuring reliable data-driven decisions.
                        </p>
                    </div>

                    <div className="top_features_step_box">
                        <img src={Seamlessdi} alt="Fast & Scalable" className="top_features_iconsimg" />
                        <h3 className="top_features_step_title">2. Fast & Scalable:</h3>
                        <p className="top_features_step_description">
                            Manages simultaneous queries and data processes efficiently without compromising performance.
                        </p>
                    </div>

                    <div className="top_features_step_box">
                        <img src={CustomIntegration} alt="Custom Integration" className="top_features_iconsimg" />
                        <h3 className="top_features_step_title">3. Custom Integration:</h3>
                        <p className="top_features_step_description">
                            Tailors to your business needs and integrates smoothly with your existing tools and systems.
                        </p>
                    </div>
                </div>

                {/* Second Row with Two Boxes */}
                <div className="top_features_grid second_row">
                    <div className="top_features_step_box">
                        <img src={PoweredAutomation} alt="AI-Powered Automation" className="top_features_iconsimg" />
                        <h3 className="top_features_step_title">4. AI-Powered Automation:</h3>
                        <p className="top_features_step_description">
                            Streamline repetitive tasks and free up valuable time by leveraging AI to automate data processing.
                        </p>
                    </div>

                    <div className="top_features_step_box">
                        <img src={SecureCompliant} alt="Secure & Compliant" className="top_features_iconsimg" />
                        <h3 className="top_features_step_title">5. Secure & Compliant:</h3>
                        <p className="top_features_step_description">
                            Your data is handled with the highest security standards, ensuring compliance with regulations.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopFeatures;
