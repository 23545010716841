import React, { useState } from "react";
import "../Styles/FAQSection.css";
import faqarrow from "../assets/faqarrow.png";
 
const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
 
  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
 
  const faqData = [
    {
      title: "What is DataDiscover.ai?",
      text: "DataDiscover.ai is a platform that helps organizations uncover insights from their data through powerful AI-driven tools.",
    },
    {
      title: "How does DataDiscover.ai improve business intelligence?",
      text: "DataDiscover.ai integrates advanced analytics and visualization tools to enhance decision-making.",
    },
    {
      title: "What types of data can be processed?",
      text: "DataDiscover.ai supports structured, semi-structured, and unstructured data formats.",
    },
    {
      title: "Is DataDiscover.ai secure and compliant?",
      text: "Yes, DataDiscover.ai ensures compliance with industry standards like GDPR and uses advanced security protocols.",
    },
    {
      title: "How can I get started with DataDiscover.ai?",
      text: "You can sign up on the platform, request a demo, or contact our sales team to begin.",
    },
  ];
 
  return (
    <div
      className="faq-container p-8 mx-auto text-zinc-700 flex-col bg-black rounded-2xl relative overflow-visible"
      id="faq"
    >
      <div className="faq-heading">
        <h2 className="text-white text-2xl font-bold">FAQs</h2>
        <div className="faq-divider"></div>
      </div>
 
      {faqData.map((item, index) => (
        <div
          key={index}
          className={`faq-item p-4 flex flex-col ${openIndex === index ? "open" : ""}
            ${index === 0 ? "rounded-t-xl" : ""}
            ${index === faqData.length - 1 ? "rounded-b-xl" : ""}`}
          onClick={() => toggleItem(index)}
          aria-expanded={openIndex === index}
        >
          <div className="faq-title font-semibold flex justify-between items-center">
            <span className="text-xl text-white">{item.title}</span>
            <img
              src={faqarrow}
              alt="toggle"
              className={`faq-arrow ${openIndex === index ? "rotate" : ""}`}
            />
          </div>
          <div className={`faq-text-container ${openIndex === index ? "expanded" : ""}`}>
            <p className="faq-text">{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
 
export default FAQSection;
 
 