import React, {useState, useEffect} from 'react'
import bgg from "../assets/toolbg.png";
import mslogo from '../assets/mslogo.png'
// Import the ContactUsForm component
import { useNavigate } from "react-router-dom";
import axios from "../caller/axiosConfig";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig"; // Import your MSAL login configuration


function MSpage() {

    const navigate = useNavigate();
    const { instance } = useMsal(); // Use MSAL instance for authentication
    const [showForm, setShowForm] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);


    // const toggleForm = () => {
    //     setShowForm(!showForm);
    // };

    useEffect(() => {
      // Trigger the video animation
      setIsLoaded(true);
  
      // Start the content animation after the video animation completes
      const contentAnimationTimeout = setTimeout(() => {
        setContentLoaded(true);
      }, 1500); // Match the video animation duration (1.5s)
  
      return () => clearTimeout(contentAnimationTimeout);
    }, []);

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    function getUserEmail() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);
    
        // Loop through each key to find the relevant data
        for (const key of keys) {
          // Check if the key indicates it may contain user info, particularly the name
          if (key.includes('login.windows.net')) { // or any other relevant keyword
            // Parse the JSON string to get the data object
            const data = JSON.parse(sessionStorage.getItem(key));
            // Check if the data object contains a name field
            if (data && data.username) {
              return data.username; // Return the user's name
            }
          }
        }
        return null; // Return null if no name is found
      }
    
      // Usage
      // const userEmail = getUserEmail();
      // if (userEmail) {
      //   console.log(`User's email: ${userEmail}`);
      // } else {
      //   console.log("Email not found in session storage.");
      // }
    
      // Function to get the user's name from session storage
      function getUserName() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);
    
        // Loop through each key to find the relevant data
        for (const key of keys) {
          // Check if the key indicates it may contain user info, particularly the name
          if (key.includes('login.windows.net')) { // or any other relevant keyword
            // Parse the JSON string to get the data object
            const data = JSON.parse(sessionStorage.getItem(key));
            // Check if the data object contains a name field
            if (data && data.name) {
              return data.name; // Return the user's name
            }
          }
        }
        return null; // Return null if no name is found
      }
    
      // Usage
      
    
      const handleClick = () => {
    
        // if (!userName || !userEmail) {
        //   console.error("User information is missing.");
        //   return;
        // }
    
        // Start login process using MSAL
        instance.loginPopup(loginRequest)
          .then((response) => {
            console.log("Login successful:", response);
            // Optionally, you can handle any post-login logic here, like storing the access token or user info
            sessionStorage.setItem('msalToken', response.accessToken);
            let userName = getUserName();
            if (userName) {
              console.log(`User's name: ${userName}`);
            } else {
              console.log("Name not found in session storage.");
            }
            // getUserName();
            let userEmail = getUserEmail();
            sessionStorage.setItem('email', userEmail);
            if (userEmail) {
              console.log(`User's email: ${userEmail}`);
            } else {
              console.log("Email not found in session storage.");
            }
            // getUserEmail();
             axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
            name: userName,
            email: userEmail,
          })
            .then((response) => {
              console.log("API response:", response.data);
              
            })
            .catch((error) => {
              console.error("Error in API call:", error);
            });
            navigate('/home');
          })
          .catch((error) => {
            console.error("Login failed:", error);
          });
      };
    
      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };
    

      return (
        <div className="min-h-screen bg-cover bg-no-repeat z-0 animate__animated animate__fadeIn overflow-hidden relative justify-center">
          {/* Video Background */}
          <div className={`herovideo_section ${isLoaded ? "zoom-in-animation" : ""} absolute top-0 left-0 w-full h-full`}>
            <video autoPlay muted loop className="background_video w-full h-full object-cover">
              <source src={`https://aisa0101.blob.core.windows.net/aibot/herobg1.mp4`} type="video/mp4" />
            </video>
          </div>
      
          {/* Content Over the Video */}
          <div 
            id="parasect" 
            className="absolute top-0 left-0 flex flex-col justify-center md:flex-row md:justify-center lg:flex-row lg:justify-center xl:flex-row xl:justify-center gap-3 text-center text-white z-10 w-full h-full animate-pulse mt-10 md:mt-[5rem] sm:mt-[5rem] "
          >
            <div className='flex flex-col justify-center lg:flex-row xl:flex-row mt-[8rem]'>
              <p className=" font-semibold text-[1.3rem] lg:text-[1.5rem] xl:text-[2rem] mt-2">Log In with </p>
              <button className='flex justify-center ' onClick={handleClick}>
                <img src={mslogo} className='w-8 h-8 mx-1 xl:w-16 xl:h-16' />
                <span className='font-semibold text-[2rem] lg:text-[1.7rem] xl:text-[3rem]'>Microsoft</span>
              </button>
            </div>
           
          </div>
        </div>
      );
  }

export default MSpage