import React from 'react';
import img1 from '../assets/clogo.png';

function FooterTool() {
  return (
    <div className="flex bg-[#D9D9D945] backdrop-blur-[50px] bg-opacity-50 flex-col mt-[-1.3rem] justify-center items-center rounded-tl-3xl rounded-tr-3xl text-white mx-6 overflow-hidden p-3 sm:block hidden">
      <div className="mb-2 flex flex-col justify-center items-center">
        <p className="text-2xl font-semibold">DataDiscover.ai</p>
        <span className="font-sm mt-[-0.3rem]">a product of</span>
      </div>
      <div className='flex justify-center items-center'>
        <img src={img1} className="h-16 w-43" />
      </div>
    </div>
  );
}

export default FooterTool;
