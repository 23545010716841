import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import './App.css';
import DragUpload from './Components/DragUpload'
import Home from './Components/Home';
import Home2 from './pages/Home2';
import Query from './Components/Query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Billing from './Components/Billing';
import SQLForm from './Components/SQLForm';
import FileUpload from './Components/FileUpload';
import { useEffect } from 'react';
import 'animate.css';
import MSpage from './Components/MSpage';
import axios from 'axios'


function App() {

  const [error, setError] = useState(null); // State for error handling
  const [databases, setDatabases] = useState([]); // State to hold the databases


  function getUserName() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
        // Check if the key indicates it may contain user info, particularly the name
        if (key.includes('login.windows.net')) { // or any other relevant keyword
            // Parse the JSON string to get the data object
            const data = JSON.parse(sessionStorage.getItem(key));
            // Check if the data object contains a name field
            if (data && data.name) {
                return data.name; // Return the user's name
            }
        }
    }
    return null; // Return null if no name is found
  }

  // Usage
  const userName = getUserName();
  if (userName) {
    console.log(`User's name: ${userName}`);
  } else {
    console.log("Name not found in session storage.");
  }


function getUserEmail() {
  const keys = Object.keys(sessionStorage);
  for (const key of keys) {
    if (key.includes('login.windows.net')) {
      const data = JSON.parse(sessionStorage.getItem(key));
      if (data && data.username) {
        return data.username;
      }
    }
  }
  return null;
}


  const userEmail = getUserEmail();
  if (userEmail) {
    console.log(`User's email: ${userEmail}`);
  } else {
    console.log("Email not found in session storage.");
  }

  const fetchDatabases = async () => {
    const email = getUserEmail(); // Get user email
    if (!email) {
      console.log("User email not found.");
      return;
    }

    try {
     // setLoading(true); // Set loading state to true
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/databases`,
        { email }
      );
      //const fetchedUserId = await fetchUserId(email);
      // console.log(fetchedUserId);
      console.log("response krao", response)

      const fetchedDatabases = response.data.databases;
      setDatabases(response.data.databases); // Set the fetched databases in state
      // localStorage.setItem('databases',JSON.stringify(fetchedDatabases));
      // setDatabases(databases);
      // console.log("Fetched databases:", response.data.databases); // Log the databases to console
    } catch (err) {
      setError(err); // Set error state
      console.error("Error fetching databases:", err); // Log the error
    } 
    // finally {
    //   setLoading(false); // Set loading state to false
    // }
  };


  return (
    <div>
      <Routes>
        <Route path='/' element={<Home2 />}> </Route>
        <Route path='/uploads' element={<DragUpload databases = {databases} setDatabases = {setDatabases}/>}> </Route>
        <Route path='/home' element={<Home databases = {databases} setDatabases = {setDatabases}/>}> </Route>
        <Route path='/query' element={<Query />}> </Route>
        <Route path='/billing' element={<Billing />}> </Route>
        <Route path='/sqlconnection' element={<SQLForm />}> </Route>
        <Route path='/uploadfile' element={<FileUpload />}></Route>
        <Route path='/login' element={<MSpage/>}></Route>
      </Routes>
      {/* <button>Get Started</button> */}
      <ToastContainer />
     
    </div>
  );
}

export default App;
