import React,{useState} from 'react';
import Navbar1 from '../Components/Navbar1';
import Hero from '../Components/Hero';
// import Features from '../Components/Features';
import AboutSection from '../Components/AboutSection';
// import Timeline from '../Components/Timeline';
// import CTA from '../Components/CTA';
import ProductVideo from '../Components/ProductVideo';
// import WhyChooseUs from '../Components/WhyChooseUs';
// import FAQFormSection from '../Components/FAQFormSection';
import Footer from '../Components/Footer';
// import PricingBar from '../Components/PricingBar';
import HowItWorks from '../Components/HowItWorks';
import TopFeatures from '../Components/TopFeatures';
import FAQSection from '../Components/FAQSection';
import Services from '../Components/Services';
import LoadingScreen from '../Components/LoadingScreen';


const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
    {isLoading ? (
      <LoadingScreen onComplete={() => setIsLoading(false)} />
    ) : (
      <>
      <Navbar1/>
      <Hero/>
      <Services/>
      <AboutSection/>
      <HowItWorks/>
      <TopFeatures/>
      <FAQSection/>
      <ProductVideo/>
      <Footer/>
      </>
      )}
    </>
  );
};

export default Home;
