import React, { useEffect, useRef, useState } from 'react';
import '../Styles/ProductVideo.css';

const ProductVideo = () => {
  const videoRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  const checkInView = () => {
    const rect = videoRef.current.getBoundingClientRect();
    if (rect.top < window.innerHeight * 0.8) {
      setIsInView(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkInView);

    return () => {
      window.removeEventListener('scroll', checkInView);
    };
  }, []);

  return (
    <div
      className={`video-section ${isInView ? 'animate' : ''}`}
      ref={videoRef}
    >
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/6X1ukBO5ECg"
          title="Product Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ProductVideo;
