import React, { useState } from "react";
import img1 from '../assets/sqlbgg.jpg';
import axios from '../caller/axiosConfig';  // If you want to use axios
import Navbar from './Navbar';
import { toast } from "react-toastify";
import Loader from '../assets/chatloader.gif'
import uploadgif from '../assets/uploadgif.gif'
import choicesection2 from '../assets/choicesection2.mp4'



function SQLForm({ databases, setDatabases }) {
  const [showWarning, setShowWarning] = useState(true); //

  const [formData, setFormData] = useState({
    hostName: "",
    username: "",
    password: "",
    databaseName: "",
  });

  // Get user email from sessionStorage
  function getUserEmail() {
    const keys = Object.keys(sessionStorage);
    for (const key of keys) {
      if (key.includes('login.windows.net')) {
        const data = JSON.parse(sessionStorage.getItem(key));
        if (data && data.username) {
          return data.username;
        }
      }
    }
    return null;
  }

  const userEmail = getUserEmail();

  // Handle input changes in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [loader, setloader] = useState(false);

  const fetchDatabases = async () => {
    const email = getUserEmail(); // Get user email
    if (!email) {
      console.log("User email not found.");
      return;
    }

    try {
      // setLoading(true); // Set loading state to true
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/databases`,
        { email }
      );
      //const fetchedUserId = await fetchUserId(email);
      // console.log(fetchedUserId);
      console.log("response krao", response)

      const fetchedDatabases = response.data.databases;
      setDatabases(response.data.databases); // Set the fetched databases in state
      // localStorage.setItem('databases',JSON.stringify(fetchedDatabases));
      // setDatabases(databases);
      // console.log("Fetched databases:", response.data.databases); // Log the databases to console
    } catch (err) {
      console.error("Error fetching databases:", err); // Log the error
    }
    // finally {
    //   setLoading(false); // Set loading state to false
    // }
  };


  // Handle form submission
  const handleSubmit = async () => {
    const { hostName, username, password, databaseName } = formData;

    if (!userEmail) {
      toast.warning("User email not found!");
      return;
    }

    if (!username) {
      toast.warning("UserName not found!");
      return;
    }

    if (!hostName) {
      toast.warning("HostName is not found!");
      return;
    }

    if (!password) {
      toast.warning("User Password not found!");
      return;
    }
    if (!databaseName) {
      toast.warning("DataBase name is not found!");
      return;
    }

    const data = {
      email_id: userEmail,
      host: hostName,
      database: databaseName,
      user: username,
      password: password,
    };

    const backendUrl = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '');  // Remove any trailing slash from the backend URL

    console.log('Sending data:', data);
    console.log('API URL:', `${backendUrl}/save_connection`);
    setloader(true)
    try {
      // Sending POST request to FastAPI route
      const response = await axios.post(`${backendUrl}/save_connection/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log("Response Data --> ", response.data);

      if (response.data.status === "success") {
        // alert("Connection details saved successfully!");

        // fetchDatabases(); // Call the fetch function

        toast.success("Connection details saved successfully!")

        setFormData({
          hostName: "",
          username: "",
          password: "",
          databaseName: "",
        });
        fetchDatabases();


      } else {
        // alert("Error saving connection details!");
        toast.error("Error saving connection details!")

      }
    } catch (error) {
      console.error("Error during the API call:", error);
      toast.error("Failed to save connection details!")
      // alert("Failed to save connection details.");
    } finally {
      setloader(false)
    }
  };
  const handleUnderstoodClick = () => {
    setShowWarning(false);
  };

  return (
    <div className="rounded-3xl py-2 px-6 flex flex-col justify-between w-full">
      {/* <Navbar /> */}
      {showWarning && (
        <div className={`fixed top-4 flex justify-center items-center right-4 bg-[#f2f2f2] border border-[#2E5BFF] rounded text-[#2E5BFF] text-sm p-3 z-50`}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="w-full">
            <p>
              <span className="font-bold">Note:</span> This feature is optimized to deliver the best performance with our proprietary data for accurate and efficient results.
            </p>
            {/* <button className="flex justify-end border-[#2E5BFF] bg-white hover:bg-gray-50 px-4 py-2 mt-4 border rounded font-bold" onClick={handleUnderstoodClick}>
              Understood
            </button> */}
          </div>
          <div className="cursor-pointer" onClick={handleUnderstoodClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"

            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

        </div>
      )}
      {/* <div > */}
        <div className={`flex flex-col justify-center items-center`} >
          <div className={`flex justify-center items-center mt-[3rem] md:mt-[1rem] lg:mt-[1rem] xl:mt-[1rem]`}>
            {/* <img src={uploadgif} className={`h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52`} /> */}
            <video autoPlay muted loop className="h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52">
              <source src={`https://aisa0101.blob.core.windows.net/aibot/choicesection2.mp4`} type="video/mp4" />
            </video>
            {/* <dotlottie-player
                        src="https://lottie.host/974b616a-0f83-4189-ae19-fea099a302e4/MJKNCVeyTB.lottie"
                        background="transparent"
                        border="2px solid black"
                        speed="1"
                        loop
                        autoplay
                        style={{ width: '50%', height: '50%' }}
                        ></dotlottie-player> */}
          </div>
          <div className={`w-full flex flex-col mt-[2rem] mb-4  ${
                      window.innerWidth <= 640 ? 'w-[95%] space-y-1' : ''
                    } `}>
            <h2 className="text-lg text-center font-semibold mb-2 sm:text-2xl sm:font-semibold sm:mb-4 md:text-2xl md:font-semibold md:mb-4 lg:text-2xl lg:font-semibold lg:mb-4 xl:text-2xl xl:font-semibold xl:mb-4">Database Configuration</h2>
            <form onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission behavior (which causes a page reload)
              handleSubmit(); // Trigger the form submit handler
            }}
            className="w-full"
            >

              <div className={`flex flex-col sm:flex-row gap-6 sm:gap-12 ${
                      window.innerWidth <= 640 ? 'space-y-[-2rem]' : ''
                    } `}>
                <div className="w-full flex flex-col ">
                  <label className="text-left text-xs md:text-sm lg:text-sm xl:text-sm font-medium mx-1 text-[#909090]">Enter host name</label>
                  <input
                    type="text"
                    name="hostName"
                    value={formData.hostName}
                    onChange={handleChange}
                    required
                    // placeholder="Folder name"
                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6  focus:outline-none px-2"

                  />
                </div>
                <div className="w-full flex flex-col ">
                  <label className="text-left text-xs md:text-sm lg:text-sm xl:text-sm font-medium mx-1 text-[#909090]">Enter username</label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6 focus:outline-none px-2"
                  />
                </div>
              </div>
              <div className={`flex flex-col sm:flex-row gap-6 sm:gap-12 ${
                      window.innerWidth <= 640 ? 'space-y-[-2rem]' : ''
                    } `}>
                <div className="w-full flex flex-col ">
                  <label className="text-left text-xs md:text-sm lg:text-sm xl:text-sm font-medium mx-1 text-[#909090]">Enter password</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6  focus:outline-none px-2"

                  />
                </div>
                <div className="w-full flex flex-col ">
                  <label className="text-left text-xs md:text-sm lg:text-sm xl:text-sm font-medium mx-1 text-[#909090]">Enter database</label>
                  <input
                    type="text"
                    name="databaseName"
                    value={formData.databaseName}
                    onChange={handleChange}
                    required
                    className="border bg-[#F0F1F2] rounded-full p-2 mb-6 focus:outline-none px-2"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"  // Change to type="submit" to allow form submission
                  className="w-[95%] md:w-[45%] lg:w-[45%] xl:w-[45%] rounded-full bg-[#2E5BFF] text-white font-semibold py-3 hover:bg-[#335fff] transition"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      {/* </div> */}
      {loader && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">

          <dotlottie-player
            src="https://lottie.host/1839b101-005a-4014-bb66-2af42bf8a9b1/moiTOzQErw.json"
            background="transparent"
            border="2px solid black"
            speed="1"
            loop
            autoplay
            style={{ width: '40%', height: '40%' }}
          ></dotlottie-player>
        </div>
      )}
    </div>
  );
}

export default SQLForm;