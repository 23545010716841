import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import img1 from '../assets/modal.jpg';
import { MdDelete } from "react-icons/md";
import axios from '../caller/axiosConfig';
import { toast } from 'react-toastify'; // Import toast and ToastContainer
import Loader from '../assets/chatloader.gif'
import 'animate.css';
import { FaFolderOpen } from "react-icons/fa";
import '../Styles/ShowDb.css';
import uploadgif from '../assets/uploadgif.gif'
import deleteVect from '../assets/deleteVect.png'
import { IoIosArrowBack } from "react-icons/io";
import choicesection2 from '../assets/choicesection2.mp4'

const ShowDb = ({ isOpen, onClose, databases, setDatabases }) => {


    useEffect(() => {
        if (isOpen) {
          // Disable scrolling when the modal is open
          document.body.style.overflow = 'hidden';
        } else {
          // Enable scrolling when the modal is closed
          document.body.style.overflow = 'auto';
        }
    
        // Cleanup when the component unmounts or modal is closed
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isOpen]);

    function getUserEmail() {
        const keys = Object.keys(sessionStorage);
        for (const key of keys) {
            if (key.includes('login.windows.net')) {
                const data = JSON.parse(sessionStorage.getItem(key));
                if (data && data.username) {
                    return data.username;
                }
            }
        }
        return null;
    }


    const handleDeleteDatabase = async (db) => {
        const userEmail = getUserEmail();
        if (userEmail) {
            console.log(`User's email: ${userEmail}`);
            console.log(db)
        } else {
            console.log("Email not found in session storage.");
        }

        if (!userEmail) {
            toast.error("User email not found.");
            return;
        }

        try {
            // console.log('Params:', {
            //     userEmail: encodeURIComponent(userEmail),
            //     db_name: encodeURIComponent(db),
            // });
            setloader(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '')}/delete_database`,
                // `${process.env.REACT_APP_BACKEND_URL}/delete_database`,

                null,
                {
                    params: {
                        userEmail: userEmail,
                        db_name: db,
                    },
                }
            );

            if (response.status === 200) {
                toast.success(response.data.message || "Database record deleted successfully");
                setDatabases(prevDatabases => prevDatabases.filter(database => database !== db));

            }
        } catch (error) {
            toast.error("Failed to delete database record.");
            console.error("Error deleting database:", error);
        } finally {
            setloader(false)
        }
    };

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [loader, setloader] = useState(false);
    const [showAll, setShowAll] = useState(false); // State to control if we show all items

    useEffect(() => {
        if (!isOpen) {
            setSearchTerm('');
            setLoading(true); // Reset loading state when modal closes
        } else {
            // Simulate fetching databases (replace with actual fetching logic)
            const fetchDatabases = async () => {
                // Simulate a delay
                await new Promise(resolve => setTimeout(resolve, 1000));
                console.log("loading --> ",loading);
                setLoading(false);
            };

            fetchDatabases();
            // setLoading(false);
        }
    }, [isOpen]);


    // useEffect(() => {
    //         // Simulate fetching databases (replace with actual fetching logic)
    //         const fetchDatabases = async () => {
    //             // Simulate a delay
    //             await new Promise(resolve => setTimeout(resolve, 1000));
    //             console.log("loading --> ",loading);
    //             setLoading(false);
    //         };

    //         fetchDatabases();
    //         // setLoading(false);
    // }, []);

    const handleDbClick = async (db) => {
        try {
            navigate('/query', { state: { selectedDb: db, databases } });
        } catch (error) {
            console.error('Error querying database:', error);
        }
    };

    if (!isOpen) return null;

    const filteredDatabases = databases.filter(db =>
        db.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const displayedDatabases = showAll ? filteredDatabases : filteredDatabases.slice(0, 3); // Show first 3 by default

    const toggleShowAll = () => {
        setShowAll((prev) => !prev); // Toggle between showing all or just the first 3
    };

    // const closeOverlaySQL = () => {
    //     //setIsOverlayOpenSQL(false); // Close overlay
    //     const showdb = document.getElementById("SQLuploads");
    
    //     showdb.classList.remove("animate__bounceInUp", "animate__fadeIn");
    
    //     // Apply the closing animation based on screen size
    //     if (window.innerWidth <= 640) {
    //       // For mobile (small screens), use bounceOutDown
    //       showdb.classList.add("animate__bounceOutDown");
    //     } else {
    //       // For larger screens, use fadeOut
    //       showdb.classList.add("animate__fadeOut");
    //     }
    
    //    setTimeout(() => {
    //     setIsOverlayOpenSQL(false); // Close overlay
    //     showdb.classList.remove("animate__bounceOutDown", "animate__fadeOut");
    //     // Reset the animation classes for the next time the modal opens
    //     showdb.classList.add("animate__bounceInUp", "animate__fadeIn");
    //   }, 1000); 
    //   };
    
    
    const handleClose = () => {
        const showdb = document.getElementById("showDb");
        // showdb.classList.remove("animate__fadeIn");
        showdb.classList.remove("animate__bounceInUp", "animate__fadeIn");
        // showdb.classList.add("animate__fadeOut");
        if (window.innerWidth <= 640) {
                  // For mobile (small screens), use bounceOutDown
                  showdb.classList.add("animate__bounceOutDown");
                } else {
                  // For larger screens, use fadeOut
                  showdb.classList.add("animate__fadeOut");
                }
        setTimeout(() => {
            onClose();
            // showdb.classList.add("animate__fadeIn");
        showdb.classList.remove("animate__bounceOutDown", "animate__fadeOut");
        // Reset the animation classes for the next time the modal opens
        showdb.classList.add("animate__bounceInUp", "animate__fadeIn");
        }, 1000)
    }

    return (
        <div id="showDb" className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 animate__animated animate__fadeIn`}>
            <div className={`bg-white rounded-3xl shadow-lg p-3 w-11/12 sm:w-3/4 max-w-3xl h-5/6 md:h-[86%] sm:h-3/4 flex flex-col sm:flex-row  relative overflow-y-auto animate__animated ${
                      window.innerWidth <= 640 ? 'animate__bounceInUp mt-[5rem] h-[95%] w-full flex flex-col' : 'animate__fadeIn'
                    }`}>
                <button
                    className="absolute top-6 left-5 text-gray-400  hover:text-gray-800  "
                    onClick={handleClose}
                >
                    <IoIosArrowBack size={24}/>
                </button>

                <div className={`w-full flex flex-col justify-center  mx-8 mt-[] ${
                      window.innerWidth <= 640 ? 'w-[82%] mt-[-1rem]' : ''
                    }`}>
                    <div className='flex justify-center items-center'>
                        {/* <img src={choicesection2} className="h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52" /> */}
                        {/* <video src={choicesection2} className="h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52"/> */}
                        <video autoPlay muted loop className="h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52">
              <source src={`https://aisa0101.blob.core.windows.net/aibot/choicesection2.mp4`} type="video/mp4" />
            </video>
                                    {/* <dotlottie-player
                        src="https://lottie.host/974b616a-0f83-4189-ae19-fea099a302e4/MJKNCVeyTB.lottie"
                        background="transparent"
                        border="2px solid black"
                        speed="1"
                        loop
                        autoplay
                        style={{ width: '67%', height: '67%' }}
                        ></dotlottie-player> */}
                    </div>
                    {/* <div className={`flex justify-center items-center mt-[-5rem] md:mt-[-3rem] lg:mt-[-3rem] xl:mt-[-3rem]`}>
                        <img src={uploadgif} className={`h-32 w-36 md:h-40 md:w-52 lg:h-40 lg:w-52`} />
                    </div> */}
                    <h2 className="text-lg mt-[2rem] sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl font-bold mb-[0.2rem] text-center text-black">Select Database</h2>
                    <p className="text-black text-center mb-2 text-xs sm:text-sm md:text-normal lg:text-normal xl:text-normal"><i>Choose a file & start chatting with us!</i></p>
                    <input
                        type="text"
                        placeholder="Search existing databases..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-4 p-2 border px-7 border-gray-300 bg-[#F0F1F2] rounded-full placeholder:text-[12px] sm:placeholder:text-[12px] md:placeholder:text-[15px] lg:placeholder:text-[15px] xl:placeholder:text-[15px]"

                    />
                    <ul className={`${!showAll ? 'overflow-hidden' : 'overflow-y-auto scrollbar-hidden'} max-h-[calc(100%_-_4rem)] flex-1 my-3 ${
                      window.innerWidth <= 640 ? 'max-h-[13rem]' : ''
                    }`}>
                        {loading ? (
                            <li className="text-center py-4 font-bold">Processing...</li>
                        ) : databases.length === 0 ? (
                            <li className="text-black font-semibold" style={{ borderColor: "#94ABFF" }}>
                                No databases created
                            </li>
                        ) : filteredDatabases.length > 0 ? (
                            displayedDatabases.map((db, index) => (
                                <li
                                    key={index}
                                    className="bg-white flex items-center justify-between p-2 px-2 cursor-pointer rounded-3xl border-2 mt-1 hover:border-blue-500 hover:border-2"
                                    style={{ borderColor: "#94ABFF" }}
                                    onClick={() => handleDbClick(db)}
                                >
                                    {/* Database name on the left */}
                                    <div className='flex justify-center items-center pl-3'>
                                        <FaFolderOpen className='w-5 h-5 text-[#2E5BFF]'/>
                                        <span className="font-semibold p-2 text-sm pl-5">{db}</span>
                                    </div>

                                    {/* Delete icon on the right */}
                                    <img src={deleteVect} className="w-4 h-4  cursor-pointer"  onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering the parent `onClick` event
                                            handleDeleteDatabase(db);
                                    }}/>
                                    
                                </li>
                            ))
                        ) : (
                            <li className="text-center py-4 font-bold">No matching databases found</li>
                        )}
                    </ul>

                    {/* "Read More" Button */}
                    {filteredDatabases.length > 3 && (
                        <div className={`text-right`}>
                            <button
                                className="text-[#2E5BFF] underline font-semibold my-[-3rem]"
                                onClick={toggleShowAll} // Toggle between showing all or first 3 entries
                            >
                                {showAll ? "See Less" : "See More"}
                            </button>
                        </div>
                    )}
                </div>
                {loader && (
                    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
                        {/* <div className="bg-white rounded-full">
                            <img src={Loader} alt="Loader" className="w-40 h-40 rounded-full" />
                        </div> */}
                        <dotlottie-player
                            src="https://lottie.host/1839b101-005a-4014-bb66-2af42bf8a9b1/moiTOzQErw.json"
                            background="transparent"
                            border="2px solid black"
                            speed="1"
                            loop
                            autoplay
                            style={{ width: '40%', height: '40%' }}
                        ></dotlottie-player>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShowDb;
