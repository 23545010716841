import React, { useEffect, useState } from "react";
import "../Styles/HowItWorks.css";
import Howitworksbox from "../assets/Howitworksbox.png";
import herobg1 from "../assets/herobg1.mp4";

const HowItWorks = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.3 } // Trigger animation when 30% of the section is visible
    );
  
    // Observe the main section
    const section = document.querySelector(".how_it_works");
    if (section) {
      observer.observe(section);
    }
  
    // Observe the main heading and sub heading
    const mainHeading = document.querySelector(".main_heading");
    const subHeading = document.querySelector(".sub_heading");
    if (mainHeading) observer.observe(mainHeading);
    if (subHeading) observer.observe(subHeading);

    // Observe each step item
    const stepItems = document.querySelectorAll(".step_item");
    stepItems.forEach((item) => observer.observe(item));
  
    // Cleanup: unobserve both the section and step items on component unmount
    return () => {
      if (section) observer.unobserve(section);
      if (mainHeading) observer.unobserve(mainHeading);
      if (subHeading) observer.unobserve(subHeading);
      stepItems.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <div className="how_it_works" id="how-it-works">
      {/* Background Video */}
      <div className="video_section">
        <video autoPlay muted loop className="background_video">
          <source src={`https://aisa0101.blob.core.windows.net/aibot/herobg1.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Heading Section */}
      <div className="text_section">
        <h2 className="main_heading">How it works?</h2>
        <p className="sub_heading">
          With just 7 easiest steps, you will reach your goal with AI-powered solutions.
        </p>

        <div className="steps_section">
          <div className="step_item ">
            <div className="step_box ">
              <h3 className="step_title">1. Data Upload</h3>
              <p className="step_description">
                Upload your data to a secure platform for analysis.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_image_box">
              <img
                src={Howitworksbox}
                alt="How it works box"
                className="how_it_works_image"
              />
            </div>
          </div>
          <div className="step_item">
            <div className="step_box ">
              <h3 className="step_title">2. Data Processing</h3>
              <p className="step_description">
                Advanced AI models process the data to identify key outputs.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_box ">
              <h3 className="step_title">3. Query Input</h3>
              <p className="step_description">
                Users can input queries or define specific analysis requirements.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_box ">
              <h3 className="step_title">4. Analysis Generation</h3>
              <p className="step_description">
                AI generates comprehensive insights based on your data.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_box ">
              <h3 className="step_title">5. Visualised Insights</h3>
              <p className="step_description">
                Easy-to-understand visuals and dashboards.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_box ">
              <h3 className="step_title">6. Actionable Output</h3>
              <p className="step_description">
                Insights ready for actionable strategies and implementation.
              </p>
            </div>
          </div>
          <div className="step_item">
            <div className="step_box">
              <h3 className="step_title">7. Data Integration</h3>
              <p className="step_description">
                The platform automatically integrates your data, ensuring seamless workflow.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
