import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilePicker } from "./FilePicker";

function FileUpload({ isModalOpen, setIsModalOpen }) {
    const [folderName, setFolderName] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('Folder Name:', folderName);
      setIsModalOpen(false); // Close the modal after submission
    };
  
    const handleCancel = () => {
      setIsModalOpen(false); // Close the modal when cancel is clicked
      navigate('/uploads'); // Navigate to the /uploads route when cancel is clicked
    };
  
    if (!isModalOpen) return null; // If modal is not open, don't render anything
  
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-100">
          <h2 className="text-xl font-bold mb-4">Enter Folder Name</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="folderName" className="block text-sm font-medium mb-2">Folder Name:</label>
            <input
              type="text"
              id="folderName"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end space-x-2">
                <button className="flex items-center bg-indigo-600 text-white rounded px-4 py-2 text-sm hover:bg-indigo-500">
                <FilePicker/>
              </button>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Submit</button>
              <button type="button" onClick={handleCancel} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  
  export default FileUpload;
  