import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import DatabaseCard from "./DatabaseCard";
import img1 from "../assets/wordlogo.png";
import img2 from "../assets/scanpdf.png";
import img3 from "../assets/standpdf.png";
import bgg from "../assets/toolbg.png";
import img4 from "../assets/excel.png";
import img7 from "../assets/db.png";
import img8 from '../assets/buss.png';
import img9 from '../assets/sale.png'
import FooterTool from "./FooterTool";
import DragUpload from "./DragUpload";
import { IoIosArrowBack } from "react-icons/io";
import SQLForm from "./SQLForm";
import BussinessCentral from "./Bussinesscentral";



// Main Component
function Options({ databases, setDatabases }) {

  const [isOverlayOpen, setIsOverlayOpen] = useState(false); // State to control overlay visibility
  const [isOverlayOpenSQL, setIsOverlayOpenSQL] = useState(false); // State to control overlay visibility
  const [isOverlayOpenBussiness, setIsOverlayOpenBussiness] = useState(false);
  const [fileType, setFileType] = useState("");
  const navigate = useNavigate();

  const handleFileTypeSelect = (type) => {
    setFileType(type);
    // navigate("/uploads", { state: { fileType: type } });
    setIsOverlayOpen(true); // Open overlay when the button is clicked
  };

  useEffect(() => {
    if (isOverlayOpenBussiness) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOverlayOpenBussiness]);

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOverlayOpen]);

  useEffect(() => {
    if (isOverlayOpenSQL) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOverlayOpenSQL]);

  const closeOverlay = () => {
    // setIsOverlayOpen(false); // Close overlay
    const showdb = document.getElementById("uploads");
    showdb.classList.remove("animate__bounceInUp", "animate__fadeIn");
    if (window.innerWidth <= 640) {
      // For mobile (small screens)
      showdb.classList.add("animate__bounceOutDown");
    } else {
      // For larger screens
      showdb.classList.add("animate__fadeOut");
    }
    setTimeout(() => {
      setIsOverlayOpen(false);
      showdb.classList.remove("animate__bounceOutDown", "animate__fadeOut");
      showdb.classList.add("animate__bounceInUp", "animate__fadeIn");
    }, 1000)
  };

  const closeOverlayBussiness = () => {
    // setIsOverlayOpen(false); // Close overlay
    const showdb = document.getElementById("bussiness");
    showdb.classList.remove("animate__bounceInUp", "animate__fadeIn");
    if (window.innerWidth <= 640) {
      // For mobile (small screens)
      showdb.classList.add("animate__bounceOutDown");
    } else {
      // For larger screens
      showdb.classList.add("animate__fadeOut");
    }
    setTimeout(() => {
      setIsOverlayOpenBussiness(false);
      showdb.classList.remove("animate__bounceOutDown", "animate__fadeOut");
      showdb.classList.add("animate__bounceInUp", "animate__fadeIn");
    }, 1000)
  };

  const closeOverlaySQL = () => {
    //setIsOverlayOpenSQL(false); // Close overlay
    const showdb = document.getElementById("SQLuploads");
    showdb.classList.remove("animate__bounceInUp", "animate__fadeIn");
    // Apply the closing animation based on screen size
    if (window.innerWidth <= 640) {
      // For mobile (small screens), use bounceOutDown
      showdb.classList.add("animate__bounceOutDown");
    } else {
      // For larger screens, use fadeOut
      showdb.classList.add("animate__fadeOut");
    }

    setTimeout(() => {
      setIsOverlayOpenSQL(false); // Close overlay
      showdb.classList.remove("animate__bounceOutDown", "animate__fadeOut");
      // Reset the animation classes for the next time the modal opens
      showdb.classList.add("animate__bounceInUp", "animate__fadeIn");
    }, 1000);
  };

  const handleBussinessOpening = () => {
    setIsOverlayOpenBussiness(true)
  }

  const handleSQL = () => {
    // navigate('/sqlconnection');
    setIsOverlayOpenSQL(true); // Open overlay when the button is clicked

  };

  return (
    <div className="">
      {isOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
                <div id="uploads"
                  className={`bg-white p-5 rounded-3xl h-[95%] w-full md:h-[85%] md:w-[85%] lg:h-[85%] lg:w-[85%] xl:h-[85%] xl:w-[63%]
                      ${window.innerWidth <= 640 ? 'InUp mt-[5rem]' : 'animate__animated animate__fadeIn'}
                  `}
                // className="bg-white p-5 rounded-3xl relative h-[85%] w-[63%]	animate__animated animate__fadeIn sm:animate__bounceInUp">
                >
                  <button

                    className="relative top-4 left-4 text-2xl  text-gray-400  hover:text-gray-800"
                    onClick={closeOverlay}
                  >
                    <IoIosArrowBack size={24} />
                  </button>
                  {/* Pass fileType to DragUpload component */}
                  <DragUpload fileType={fileType} databases={databases} setDatabases={setDatabases} />
                </div>
              </div>
            )}
            {isOverlayOpenSQL && (
              <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
                <div id="SQLuploads"
                  className={`bg-white p-5 rounded-3xl relative h-[95%] w-full md:h-[85%] md:w-[85%] lg:h-[85%] lg:w-[85%] xl:h-[85%] xl:w-[63%] animate__animated ${window.innerWidth <= 640 ? 'animate__bounceInUp mt-[5rem]' : 'animate__fadeIn'
                    }`}
                >
                  <button
                    className="absolute top-6 left-5 text-2xl  text-gray-400  hover:text-gray-800"
                    onClick={closeOverlaySQL}
                  >
                    <IoIosArrowBack size={24} />
                  </button>
                  {/* Pass fileType to DragUpload component */}
                  <SQLForm databases={databases} setDatabases={setDatabases} />
                </div>
              </div>
            )}
            {isOverlayOpenBussiness && (
              <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
                <div id="bussiness"
                  className={`bg-white p-5 rounded-3xl relative h-[95%] w-full md:h-[85%] md:w-[85%] lg:h-[85%] lg:w-[85%] xl:h-[85%] xl:w-[63%] animate__animated ${window.innerWidth <= 640 ? 'animate__bounceInUp mt-[5rem]' : 'animate__fadeIn'
                    }`}
                >
                  <button
                    className="absolute top-6 left-5 text-2xl  text-gray-400  hover:text-gray-800"
                    onClick={closeOverlayBussiness}
                  >
                    <IoIosArrowBack size={24} />
                  </button>
                  {/* Pass fileType to DragUpload component */}
                  <BussinessCentral databases={databases} setDatabases={setDatabases} />
                </div>
              </div>
            )}

      <div
        className="min-h-screen z-0  "
      // style={{
      //   backgroundImage: `url(${bgg})`,
      //   objectPosition: 'center calc(85%)', // Adjust if precise positioning is needed
      //   backgroundPosition: 'center calc(50% - 23%)',  // Adjusts the vertical positioning
      // }}
      >

        <div className="">
          <div className=" flex-col justify-center text-center text-white z-0 sm:block hidden">
            <p className="font-bold text-[4rem]">DataDiscover </p>
            <p className="font-semibold text-[1.2rem]">Unlock answers from your data!</p>
          </div>

          <div className="flex justify-center mt-12 text-center flex-wrap mb-[1.3rem] toolslist">
            {/* Standard PDF */}
            <div
              className=" bg-[#404040] backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-70 w-50 toolslist1"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img3}
                  className="h-21 w-21 transition-transform duration-400 hover:scale-125"
                  alt="Standard PDF"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">Standard PDF</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-10 transition-all duration-200 "
                    onClick={() => handleFileTypeSelect("standard_pdf")}
                  >
                    Try now
                  </button>

                </div>
              </div>
            </div>

            {/* Scanned PDF */}
            <div
              className=" bg-[#404040] backdrop-blur-[150px] bg-opacity-60 rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all w-50ition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist2"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img2}
                  className="h-20 w-20 transition-transform duration-400 hover:scale-125"
                  alt="Scanned PDF"
                />
              </div>

              <div className="p-2 flex flex-col justify-between ">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">Scanned PDF</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-10 transition-all duration-200 "
                    onClick={() => handleFileTypeSelect("scanned_pdf")}
                  >
                    Try now
                  </button>
                </div>
              </div>
            </div>

            {/* Word Documents */}
            <div
              className=" bg-[#404040] backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist3"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img1}
                  className="h-20 w-20 transition-transform duration-400 hover:scale-125"
                  alt="Word Documents"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">Word File</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-10 transition-all duration-200 "
                    onClick={() => handleFileTypeSelect("word_files")}
                  >
                    Try now
                  </button>
                </div>
              </div>
            </div>

            {/* CSV Files */}
            <div
              className=" bg-[#404040] backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist4"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img4}
                  className="h-20 w-20 transition-transform duration-400 hover:scale-125"
                  alt="XLSX/CSV Files"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE] ">Excel File</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-10 transition-all duration-200 "
                    onClick={() => handleFileTypeSelect("csv_files")}
                  >
                    Try now
                  </button>
                </div>
              </div>
            </div>
            <div
              className=" bg-[#404040] backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist5"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img7}
                  className="h-20 w-20 transition-transform duration-400 hover:scale-125"
                  alt="SQL Database"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">SQL Database</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Enter your database configuration</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-10 transition-all duration-200 "
                    onClick={handleSQL} // Trigger handleSQL function on button click
                  >
                    Try now
                  </button>
                </div>
              </div>
            </div>
            <div
              className=" my-6 bg-[#404040] backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist6"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img8}
                  className="h-20 w-20 transition-transform duration-400 hover:scale-125"
                  alt="Word Documents"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">Business Central</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-8 transition-all duration-200 "
                    onClick={handleBussinessOpening}
                  >
                    Try now
                  </button>
                </div>
              </div>
            </div>
            <div
              className=" bg-[#404040] my-6 backdrop-blur-[150px] bg-opacity-60  rounded-3xl border border-[#FFFEFE8A] shadow-lg transition-all duration-150 hover:shadow-xl m-4 h-72 w-50 toolslist7"
              style={{ width: "13rem" }}
            >
              <div className="mt-2 mx-2 bg-[#FFFEFE] rounded-3xl h-32 w-50 flex items-center justify-center">
                <img
                  src={img9}
                  className="h-20 w-27 transition-transform duration-400 hover:scale-125"
                  alt="Word Documents"
                />
              </div>
              <div className="p-2 flex flex-col justify-between">
                <div className="mb-2">
                  <h5 className="text-lg font-semibold text-[#FFFEFE]">Salesforce</h5>
                  <p className="text-sm font-normal text-[#FFFEFE] mb-2">Click below to upload the file</p>
                </div>
                <div className="">
                  <button
                    className="bg-[#FFFEFE] text-black font-semibold rounded-full py-2 px-8 transition-all duration-200 "

                  >
                    Coming Soon
                  </button>
                </div>
              </div>
            </div>      
     </div>
        </div>
        <FooterTool />
      </div>
    </div>
  );
}

export default Options;
