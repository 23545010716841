import React, { useState, useRef, useEffect } from "react";
import axios from "../caller/axiosConfig";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IoSend } from "react-icons/io5";
import { toast } from 'react-toastify'; // Import toast and ToastContainer
// import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Navbar from "./Navbar";
import img1 from '../assets/modal.jpg'
import Loader from '../assets/chatloader.gif'
import ShowDb from "./ShowDb";
import { FilePicker } from "./FilePicker";
import { RxCross2 } from "react-icons/rx";
import uploadgif2 from '../assets/uploadgif2.gif'



const DragUpload = ({ fileType , databases, setDatabases}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { fileType } = location.state || {}; // Get fileType from the location state
  // const [downloadUrl, setDownloadUrl] = useState(null); // New state to store download URL
  const [downloadUrl, setDownloadUrl] = useState([]); // Initialize as an empty array

  let typee = 'hello'; // Use let instead of const

  // Determine typee based on fileType
  if (fileType === 'scanned_pdf') {
    typee = 'Scanned Pdf';
  } else if (fileType === 'standard_pdf') {
    typee = 'Standard Pdf';
  } else if (fileType === 'word_files') {
    typee = 'Word Document';
  } else if (fileType === 'csv_files') {
    typee = 'CSV File';
  }

  console.log("fileType hai ", fileType)

  const [fileName, setFileName] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]); // To store selected files
  const [folderName, setFolderName] = useState(""); // State to store the folder name
  const fileInputRef = useRef(null);
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null); // State for error handling
  // const [databases, setDatabases] = useState([]); // State to hold the databases
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isOpen, setIsOpen] = useState(false);
  const [isUrlExpanded, setIsUrlExpanded] = useState(false); // Track whether URL is expanded or not
  const [showWarning, setShowWarning] = useState(true); //


  // Function to get the user's email from session storage

  // Usage
  const userEmail = getUserEmail();
  if (userEmail) {
    console.log(`User's email: ${userEmail}`);
  } else {
    console.log("Email not found in session storage.");
  }


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDatabasesClick = () => {
    setIsModalOpen(true); // Open the modal when databases are clicked
  };

  const fetchDatabases = async () => {
    const email = getUserEmail(); // Get user email
    if (!email) {
      console.log("User email not found.");
      return;
    }

    try {
     // setLoading(true); // Set loading state to true
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/databases`,
        { email }
      );
      //const fetchedUserId = await fetchUserId(email);
      // console.log(fetchedUserId);
      console.log("response krao", response)

      const fetchedDatabases = response.data.databases;
      setDatabases(response.data.databases); // Set the fetched databases in state
      // localStorage.setItem('databases',JSON.stringify(fetchedDatabases));
      // setDatabases(databases);
      // console.log("Fetched databases:", response.data.databases); // Log the databases to console
    } catch (err) {
      setError(err); // Set error state
      console.error("Error fetching databases:", err); // Log the error
    } 
    // finally {
    //   setLoading(false); // Set loading state to false
    // }
  };

  // Fetch databases when the component mounts
  useEffect(() => {
    fetchDatabases(); // Call the fetch function
  }, []);


  // Handle file upload
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) {
      toast.error("Folder does not contain any files");
      return;
    }
    const fileNames = files.map((file) => file.name).join(", ");
    setFileName(fileNames);
    setSelectedFiles(files);
    setIsFileUploaded(true);

    // Extract the folder name from the first file's webkitRelativePath
    const firstFilePath = files[0].webkitRelativePath;

    if (firstFilePath) {
      // Assuming the folder name is the first directory in the relative path
      const extractedFolderName = firstFilePath.split("/")[0];
      setFolderName(extractedFolderName);
      console.log("Uploaded Folder Name:", extractedFolderName); // Log the folder name
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // const handlefileuploads= ()=>{
  //   navigate('/uploadfile');
  // }


  function getUserEmail() {
    const keys = Object.keys(sessionStorage);
    for (const key of keys) {
      if (key.includes('login.windows.net')) {
        const data = JSON.parse(sessionStorage.getItem(key));
        if (data && data.username) {
          return data.username;
        }
      }
    }
    return null;
  }
  // Function to create the database


  const createDatabase = async () => {
    if (selectedFiles.length === 0) {
      toast.error("Please upload files before creating the database");
      return;
    }
    // if (fileType !== 'application/pdf') {
    //   console.log("continuing")
    //   return;
    // }

    if (!userEmail) {
      toast.error("User email not found. Please make sure you are logged in.");
      return;
    }

    const formData = new FormData();
    formData.append("folder_name", folderName);
    formData.append("email", userEmail);
    formData.append("file_type", fileType);

    console.log("folder_name", folderName)
    console.log("email", userEmail)
    console.log("file_type", fileType)

    for (const filee of selectedFiles) {
      try {
        const response = await fetch(filee.url);
        if (!response.ok) {
          throw new Error(`Error fetching file: ${filee.url}`);
          // setrefresh(false);
        }

        formData.append("files", filee, filee.name);
      } catch (error) {
        console.error("Fetch error:", error);
        toast.error(`Error fetching file: ${filee.name}`);
        // setrefresh(false);
        return;
      }
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/database/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("responsee new", response)
      if (response.data.message === 'Database already exists for this folder name and email.') {
        toast.warning("Database already exist!")
      }
      // console.log(response)
      else if (response.data.message === 'Database not created') {
        console.log("neee error ", response.data.message)
        toast.error(`Error: ${response.data.message}`);
        // setrefresh(false);
      } else {
        // setrefresh(true);
        toast.success(`Success: ${response.data.message}`);
        fetchDatabases();
        console.log("Response:", response.data);
      }
      const etaInSeconds = parseFloat(response.data.eta) * 60;
      setTimeLeft(etaInSeconds);
      setFileName("");
      setIsFileUploaded(false);
      setSelectedFiles([]);
      setFolderName("");
    } catch (error) {
      if (error.response) {
        console.error("Backend error:", error.response.data);
        toast.error(`Backend Error: ${error.response.data.message || error.message}`);
      } else if (error.request) {
        console.error("No response from backend:", error.request);
        toast.error("No response from backend. Please try again later.");
      } else {
        console.error("Error:", error.message);
        toast.error(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false)
    }
  };

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft <= 0) {
      // setrefresh(false)
      // refreshWindow();
      fetchDatabases();
      // window.location.reload();
      return;
    }
    // Countdown function
    const timerId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId); // Cleanup timer on unmount
  }, [timeLeft]);

  const formatTime = () => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = Math.floor(timeLeft % 60);

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  const toggleFileUploadModal = () => {
    if (isFileUploadModalOpen) {
      setFolderName("");  // Clear the folder name input
      setDownloadUrl([]);  // Clear the download URL
    }
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
  };

  const handleFileUploadfromdrive = async () => {
    // if (downloadUrl.length === 0  || !folderName || !userEmail || !fileType) {
    //   toast.error("Please make sure all fields are filled.");
    //   return;
    // }

    if (downloadUrl.length === 0) {
      toast.error("Please make sure File is selected");
      return;
    }

    if (!folderName) {
      toast.error("Enter Folder Name");
      return;
    }

    if (!userEmail) {
      toast.warning("Email not found !");
      return;
    }

    // if (downloadUrl) {
    //   toast.success("file is selected")
    // }

    console.log("downloadUrllllllllllllllll", downloadUrl)

    // Prepare the data to send
    const fileUrls = [downloadUrl]; // Assuming you're only uploading one URL at a time.

    console.log("fileUrlsssssssssssssss", fileUrls)

    const formData = new FormData();
    formData.append("folder_name", folderName);
    formData.append("email", userEmail);
    formData.append("file_type", fileType);
    formData.append("file_urls", fileUrls)

    console.log("hello")
    console.log("folder_name", folderName)
    console.log("email", userEmail)
    console.log("file_type", fileType)
    console.log("fileUrls", fileUrls)

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload-file/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const etaInSeconds = parseFloat(response.data.eta) * 60;
      setTimeLeft(etaInSeconds);

      if (response.data.message === 'Database not created') {
        console.log("testing drive..........")
        console.log(response.data);
        toast.error(`Error: ${response.data.message}`);
        // setrefresh(false);
      } else {
        // setrefresh(true);
        console.log("testing drive..........")
        toast.success("Database created successfully!");
        fetchDatabases();
        console.log("Response:", response.data);
        setFolderName("");  // Clear the folder name input
        setDownloadUrl([]);
        setIsFileUploadModalOpen(false)
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("An error occurred during the upload.");
    } finally {
      setLoading(false);
    }
  };

  const handleFilePick = (url) => {
    console.log("Download URL received in DragUpload:", url);
    setDownloadUrl((prevUrls) => [...prevUrls, url]);
  };

  // const handleMouseEnter = () => {
  //   const showdb = document.getElementById("uploaddata");
  //   showdb.classList.add("animate__pulse"); // Add the animation class
  // };

  // const handleMouseLeave = () => {
  //   const showdb = document.getElementById("uploaddata");
  //   // Remove the animation class after 1 second
  //   setTimeout(() => {
  //     showdb.classList.remove("animate__pulse");
  //   }, 3000); 
  // };

  // console.log(downloadUrl)
  // Limit the displayed URL length
  // const truncatedUrl = downloadUrl && !isUrlExpanded ? `${downloadUrl.slice(0, 1)}...` : downloadUrl;
  const truncatedUrl = downloadUrl;

  const handleUnderstoodClick = () => {
    setShowWarning(false);
  };

  return (
    <div className="absoute top-0 inset-0 ">
      {fileType === 'csv_files' && showWarning && (
        <div className="fixed top-4 flex justify-center items-center right-4 bg-[#f2f2f2] border border-[#2E5BFF] rounded text-[#2E5BFF] text-sm p-3  z-50">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="w-full">
          <p>
            <span className="font-bold">Note:</span> Excel feature is optimized to deliver the best performance with our proprietary data for accurate and efficient results.
          </p>
          {/* <button className="flex justify-end border-[#2E5BFF] bg-white hover:bg-gray-50 px-4 py-2 mt-4 border rounded font-bold" onClick={handleUnderstoodClick}>
            Understood
          </button> */}
        </div>
        <div className="cursor-pointer" onClick={handleUnderstoodClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"

          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

      </div>
      )}
      <div className="rounded-3xl py-2 px-6 flex flex-col justify-between">
        {timeLeft > 0 && (
          <div className="flex items-center text-center bg-[#2E5BFF] text-white rounded-2xl px-4 py-2 p-auto text-md w-[30rem] m-auto">
            <p className="text-sm">Please wait... Database setup in progress {formatTime()} left.</p>
          </div>
        )}
        <div className={`flex justify-center items-center mt-8 md:mt-8 lg:mt-[-2.5rem] xl:mt-[-2.5rem]`}>
          {/* <img src={uploadgif2} className={`h-[60%] w-[60%] m-[-7rem] md:h-1/2 md:w-1/2 lg:h-[40%] lg:w-[40%] xl:h-[40%] xl:w-[40%]`} /> */}
          <dotlottie-player
              src="https://lottie.host/1ee21600-5801-4d7b-937a-1a91a7bf3ae6/UoE1uEZBbf.lottie"
              background="transparent"
              border="2px solid black"
              speed="1"
              loop
              autoplay
              style={{ width: '25%', height: '25%' }}
            ></dotlottie-player>
        </div>
        <div className="text-center mb-4 mt-[-3rem]">
          <h1 className={`text-lg mt-[2rem] sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold mb-2  `}>Chat with your data!</h1>
          <p className={`text-xs sm:text-normal md:text-normal lg:text-lg xl:text-lg mb-1`}>Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
          <p className={`mt-6 flex flex-col justify-center items-center md:flex-row md:justify-center md:items-center lg:flex-row lg:justify-center lg:items-center xl:flex-row xl:justify-center xl:items-center`}><button id="uploaddata" className="bg-[#2E5BFF80] text-sm sm:text-sm md:text-sm lg:text-base xl:text-base text-white rounded-full py-3 px-6 mx-3 hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" onClick={handleUploadClick}>Upload your data</button> <span className={`font-medium  ${
          window.innerWidth <= 640 ? 'my-1 text-xs' : '' 
          }`}>or</span> < button onClick={handleDatabasesClick} className="bg-[#2E5BFF80] text-sm sm:text-sm md:text-sm lg:text-base xl:text-base text-white rounded-full py-3 px-6 mx-3 hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" disabled={!!fileName}
              style={{ cursor: fileName ? 'not-allowed' : 'pointer' }} >Try existing database</button> <span className={`font-medium  ${
                window.innerWidth <= 640 ? 'my-2 text-xs' : '' // Add margin to separate the "or" span when in column layout
              }`}>or</span> < button className="bg-[#2E5BFF80] text-sm sm:text-base md:text-sm  lg:text-base xl:text-base text-white rounded-full py-3 px-6 mx-3 hover:scale-105 hover:shadow-lg transition-all duration-200 ease-in-out" onClick={() => toggleFileUploadModal(true)} disabled={!!fileName}
              style={{ cursor: fileName ? 'not-allowed' : 'pointer' }}>Upload via OneDrive</button></p>
        </div>

        {fileName && (
          <div className="text-center mb-1 max-h-5 sm:max-h-10 md:max-h-10 lg:max-h-16 xl:max-h-16   max w-full overflow-y-auto scrollbar-hidden">
            <p className="text-md text-[#2E5BFF]">Selected Files: {fileName}</p>
          </div>
        )}

        <div className="flex justify-center text-center items-center">
          <div className="flex">
            <input
              type="file"
              webkitdirectory="true"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileUpload}
              multiple
            />
            <button
              onClick={createDatabase}
              disabled={!fileName}
              className={`w-60 mt-3 flex justify-center md:w-96 lg:w-96 xl:w-96 bg-[#2E5BFF] text-white rounded-full px-4 mb-4 md:mt-2 lg:mt-2 xl:mt-2 py-3 text-sm hover:bg-[#0037ff] ${!fileName ? 'cursor-not-allowed' : ''} `}>
              <span className="font-medium text-md">Create Database</span>
              {/* <span className="ml-2 text-lg font-bold"><IoSend /></span> */}
            </button>
            {/* )} */}
          </div>
          {/* </div> */}
        </div>
        <div className={`${isFileUploadModalOpen ? 'opacity-100 block' : 'opacity-0 hidden'} fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50`}>
          <div className={`flex items-center justify-center bg-white rounded-3xl  w-[50%]	h-[65%]	 animate__animated animate__fadeIn ${
          window.innerWidth <= 640 ? ' w-[95%]	h-[64%]' : ''
        }`}>

            <div className={`w-full flex flex-col relative items-center justify-center  mt-[-10rem] `}>
              <button
                className={`absolute top-[-2rem] right-5 text-2xl text-[#DD2025] cursor-pointer ${
                  window.innerWidth <= 640 ? 'top-[-1rem] right-5 ' : ''
                }`}
                aria-label="Close"
                onClick={toggleFileUploadModal}
              >
                <RxCross2 size={20} />
              </button>

              {/* Title */}
              <h2 className="text-xl font-semibold text-center mb-4">Upload Folder</h2>

              {/* Input Field */}
              <div className={`flex w-[85%]  justify-center items-center ${
                  window.innerWidth <= 640 ? 'flex flex-col' : ''
                  }
                `}>
                <div className="w-[85%] flex flex-col ">
                  <label className="text-left text-sm font-medium mx-1 text-[#909090]">Folder name</label>
                  <input
                    type="text"
                    id="folder-name"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    className="border bg-gray-200 rounded-full p-2 mb-6 bg-transparent focus:outline-none px-2"
                    required
                  />
                </div>
                <FilePicker onFilePick={handleFilePick} />
              </div>
              {/* {downloadUrl && (
                <div className="text-center mt-4 relative">
                  <p className="text-md text-indigo-500">
                    {downloadUrl && downloadUrl.length > 0 && downloadUrl.map((url, index) => {
                      const truncatedUrl = url.length > 30 ? url.slice(0, 30) + "..." : url; 
                      return (
                        <div key={index}>
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {truncatedUrl} 
                          </a>
                        </div>
                      );
                    })}
                  </p>
                </div>
              )} */}

              {downloadUrl && (
                <div className="text-center mt-4 relative max-h-50 w-full overflow-y-auto scrollbar-hidden">
                  <p className="text-md text-indigo-500">
                    {downloadUrl && downloadUrl.length > 0 && downloadUrl.map((url, index) => {
                      const truncatedUrl = url.length > 50 ? url.slice(0, 50) + "..." : url;
                      return (
                        <div key={index}>
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {truncatedUrl}
                          </a>
                        </div>
                      );
                    })}
                  </p>
                </div>
              )}
              {/* Upload Button */}
              <button 
                  className={`w-[50%] rounded-full bg-[#2E5BFF] mt-6 mb-[-5rem] text-white font-semibold py-3 hover:bg-[#335fff] transition ${!downloadUrl || downloadUrl.length === 0 ? 'cursor-not-allowed' : ''}`}
                  onClick={handleFileUploadfromdrive}
                  disabled={!downloadUrl || downloadUrl.length === 0}
              >
                
                Upload
              </button>
              {/* </div> */}
            </div>
          </div>
          
        </div>
        {isLoading && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
            <dotlottie-player
              src="https://lottie.host/1839b101-005a-4014-bb66-2af42bf8a9b1/moiTOzQErw.json"
              background="transparent"
              border="2px solid black"
              speed="1"
              loop
              autoplay
              style={{ width: '40%', height: '40%' }}
            ></dotlottie-player>
          </div>

        )}
        <ShowDb isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} databases={databases} setDatabases={setDatabases} />
      </div>
    </div >
  );
};

export default DragUpload;
