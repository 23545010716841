
import React, { useState } from 'react';
import '../Styles/Footer.css'; // Import the updated CSS file for the footer
import bcktop from "../assets/bcktop.png";
import India from "../assets/India.png";
import UAE from "../assets/UAE.png";
import Singapore from "../assets/Singapore.png";
import ContactUsForm from './ContactUsForm'; // Assuming this is the correct import for the ContactUsForm component
 
const Footer = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false); // State to control the contact form popup visibility
 
  // Show or hide the back-to-top button based on scroll position
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };
 
  // Scroll to the top of the page when the back-to-top button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
 
  // Attach scroll event listener on component mount and cleanup on unmount
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
 
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
  // Toggle the visibility of the contact form
  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };
 
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content flex justify-center gap-3">
          <ul className="footer__links flex gap-12 text-sm mb-8">
            <li>
              <a href="#services" className="footer__link">Services</a>
            </li>
            <li>
              <a href="#how-it-works" className="footer__link">How it works</a>
            </li>
            <li>
              <a href="#top-features" className="footer__link">Top Features</a>
            </li>
            <li>
              <a href="#contact" className="footer__link" onClick={toggleContactForm}>Contact</a>
            </li>
          </ul>
        </div>
 
{/* Office Address Section */}
<div className="footer_flags">
  <div className="footer__address text-center">
    <div className='flag_names' >
    <img src={India} alt="India" className="footer__flag" />
    <h2>India</h2>
    </div>
    <p className="footer__address-text">
    Tower B, office no. 1103 & 1104, 11th<br />
    floor, Spaze IT Tech Park, Sohna<br />
    Road, Gurugram, India
    </p>
  </div>
 
  <div className="footer__divider"></div> {/* Vertical Divider */}
 
  <div className="footer__address text-center">
  <div className='flag_names' >
    <img src={UAE} alt="UAE" className="footer__flag" />
    <h2>UAE</h2>
    </div>
    <p className="footer__address-text">
    Unique World Business<br />
    Centre, Al Karama, Dubai,<br />
      UAE
    </p>
  </div>
 
  <div className="footer__divider"></div> {/* Vertical Divider */}
 
  <div className="footer__address text-center">
  <div className='flag_names' >
    <img src={Singapore} alt="Singapore" className="footer__flag" />
    <h2>Singapore</h2>
    </div>
    <p className="footer__address-text">
    68 Circular Road<br />
    #02-01 Singapore<br />
    (049422)
    </p>
  </div>
</div>
 
 
        <span className="footer__copy">
          &#169; Meridian Solutions Pvt Ltd 2024. All rights reserved
        </span>
 
        {showBackToTop && (
          <a
            href="#top"
            className="footer__back-to-top"
            onClick={scrollToTop}
          >
            <img src={bcktop} alt="Back to Top" className="back-to-top-image" />
          </a>
        )}
      </div>
 
      {/* Show the ContactUsForm as a popup if showContactForm is true */}
      {showContactForm && (
        <ContactUsForm onClose={toggleContactForm} />
      )}
    </footer>
  );
};
 
export default Footer;
 
 