import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
// import img1 from '../assets/image.png'; // Ensure this path is correct
// import img2 from '../assets/meridian_logo.png';
// import { FaHome, FaDatabase } from "react-icons/fa"; // Correct import for FaHome and FaDatabase
// import { IoPersonSharp } from "react-icons/io5";
import { toast } from 'react-toastify'; // Import toast and ToastContainer
// import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
// import { AiOutlineLogout } from "react-icons/ai";
import SignOutButton from './SignOutButton'; // Import the SignOutButton component\
import axios from '../caller/axiosConfig';
// import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import info from '../assets/info.gif'
import Loader from '../assets/chatloader.gif'
import ShowDb from './ShowDb';
import img1 from '../assets/clogo.png';


import chaticon from '../assets/chaticon.png'
import navprofile from '../assets/navprofile.png'

const Navbar = ({ databases, setDatabases }) => {


    const [loading, setLoading] = useState(true); // State for loading status
    const [error, setError] = useState(null); // State for error handling
    console.log("Session Storage Content:", sessionStorage);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const profileDropdownRef = useRef(null);  // Create a reference for the profile dropdown


    // Function to get the user's email from session storage
    function getUserEmail() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);

        // Loop through each key to find the relevant data
        for (const key of keys) {
            // Check if the key indicates it may contain user info, particularly the name
            if (key.includes('login.windows.net')) { // or any other relevant keyword
                // Parse the JSON string to get the data object
                const data = JSON.parse(sessionStorage.getItem(key));
                // Check if the data object contains a name field
                if (data && data.username) {
                    return data.username; // Return the user's name
                }
            }
        }
        return null; // Return null if no name is found
    }

    // Usage
    const userEmail = getUserEmail();
    if (userEmail) {
        console.log(`User's email: ${userEmail}`);
    } else {
        console.log("Email not found in session storage.");
    }

    // Function to get the user's name from session storage
    function getUserName() {
        // Retrieve all keys in session storage
        const keys = Object.keys(sessionStorage);

        // Loop through each key to find the relevant data
        for (const key of keys) {
            // Check if the key indicates it may contain user info, particularly the name
            if (key.includes('login.windows.net')) { // or any other relevant keyword
                // Parse the JSON string to get the data object
                const data = JSON.parse(sessionStorage.getItem(key));
                // Check if the data object contains a name field
                if (data && data.name) {
                    return data.name; // Return the user's name
                }
            }
        }
        return null; // Return null if no name is found
    }

    // Usage
    const userName = getUserName();
    if (userName) {
        console.log(`User's name: ${userName}`);
    } else {
        console.log("Name not found in session storage.");
    }

    // Toggle dropdown visibility
    const toggleProfileDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    };

    // Handle clicks outside of the profile dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
                setIsProfileDropdownOpen(false); // Close the dropdown if clicked outside
            }
        };

        // Add event listener on component mount
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const fetchDatabases = async () => {
        const email = getUserEmail(); // Get user email
        if (!email) {
            console.log("User email not found.");
            return;
        }

        try {
            setLoading(true); // Set loading state to true
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/databases`,
                { email }
            );
            //const fetchedUserId = await fetchUserId(email);
            // console.log(fetchedUserId);
            console.log("response krao", response)

            setDatabases(response.data.databases); // Set the fetched databases in state
            // console.log("Fetched databases:", response.data.databases); // Log the databases to console
        } catch (err) {
            setError(err); // Set error state
            console.error("Error fetching databases:", err); // Log the error
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    useEffect(() => {
        fetchDatabases(); // Call the fetch function
    }, []);

    const handleDatabasesClick = () => {
        setIsModalOpen(true); // Open the modal when databases are clicked
    };

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const handleDeleteClick = () => {
        setIsModalDeleteOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalDeleteOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            setLoader(true)
            const email = getUserEmail(); // Get user email
            if (!email) {
                console.log("User email not found.");
                return;
            }
            if (databases.length <= 0) {
                toast.error("No database Exist!");
                return
            }

            console.log(email)
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/delete_all_database?userEmail=${encodeURIComponent(email)}`
            );

            if (response.status === 200) {
                console.log("Databases deleted");
                toast.success("Database deleted successfully!");
                setDatabases([])
                // Add success logic here
            }

            setIsModalDeleteOpen(false); // Close the modal after deletion
        } catch (error) {
            console.error("Error deleting databases", error);
            toast.error(`Error: ${error}`);

            // Add error handling here
        } finally {
            setLoader(false)
        }
    };

    return (
        <body className="w-full"> {/* Dark background color */}
            <nav className=" px-2 mx-1 sm:mx-4 md:mx-6 py-2 flex justify-between items-center "> {/* Lighter navbar background with custom shadow */}
                <Link to="/" className="text-lg sm:text-3xl md:text-3xl lg:text-3xl font-semibold sm:font-bold md:font-bold lg:font-bold leading-none"> {/* Use Link for navigation */}
                    <p className='text-white'>DataDiscover.ai</p>
                    <img src={img1} className="h-6 w-30 mt-1  sm:block md:hidden lg:hidden xl:hidden" />
                </Link>
               
                <ul className={`top-16 left-0 w-full z-20 bg-transparent flex items-center transform justify-end transition-all duration-300 ease-in-out text-sm sm:text-md md:p-2`}>
                    <li className="flex items-center p-2 sm:p-4 md:p-4 lg:p-4 xl:p-4">
                        <button onClick={handleDatabasesClick} className="flex flex-col items-center text-black relative group transition-transform transform hover:scale-105">                            <span className="">
                            <img src={chaticon} className=' h-4 w-4 sm:h-5 sm:w-5 md:h-5 md:w-5 lg:h-5 lg:w-5' />
                        </span>
                            <span className="text-xs sm:text-[14px] mt-1 font-medium text-white ">Start Chat</span>
                        </button>
                    </li>
                    <li className="relative items-center p-2 sm:p-4 md:p-4 lg:p-4 xl:p-4 z-50  " >
                        <button onClick={toggleProfileDropdown} className="flex flex-col items-center text-black relative group transition-transform transform hover:scale-105">
                            <span className="">
                                <img src={navprofile} className='h-4 w-4 sm:h-5 sm:w-5 md:h-5 md:w-5 lg:h-5 lg:w-5' />
                            </span>
                            <span className="text-xs sm:text-[14px] mt-1 font-medium text-white sm:text">Profile</span>
                        </button>
                        {isProfileDropdownOpen && (
                            <div ref={profileDropdownRef} className="absolute text-white top-16 right-4 bg-[#D9D9D945]  backdrop-blur-3xl bg-opacity-50  shadow-md rounded-md p-1 z-10 animate__animated animate__fadeIn" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.5) 0px 8px 16px -8px;' }}>
                                <div className="px-1 py-2 sm:px-4 sm:py-3 md:px-4 md:py-3 lg:px-4 lg:py-3 xl:px-4 xl:py-3">
                                    <span className="block text-xs sm:text-base md:text-base lg:text-base xl:text-base font-bold">{userName}</span>
                                    <span className="block text-xs sm:text-base md:text-base lg:text-base xl:text-base font-normal text-white truncate">{userEmail}</span>
                                </div>
                                <hr />
                                <ul className="py-1 sm:py-2 md:py-2 lg:py-2 xl:py-2">
                                    <li className='cursor-pointer group'>
                                        <Link to="/billing" className="block p-1 sm:p-2 md:p-2 lg:p-2 xl:p-2 text-xs sm:text-base md:text-base lg:text-base xl:text-base border-transparent border-l-4  hover:scale-105 ">Billing</Link>
                                    </li>
                                    <li className='cursor-pointer group border-t '>
                                        <Link to="#" className="block p-1  sm:p-2 md:p-2 lg:p-2 xl:p-2 text-xs sm:text-base md:text-base lg:text-base xl:text-base border-transparent border-l-4  hover:scale-105">Databases Created : {databases.length}</Link>
                                    </li>
                                    <div >
                                        <li className='cursor-pointer group border-t w-full ' >
                                            <button className="w-full text-left text-xs sm:text-base md:text-base lg:text-base xl:text-base block p-1 sm:p-2 md:p-2 lg:p-2 xl:p-2 border-transparent border-l-4  hover:scale-105 " onClick={handleDeleteClick}>Delete Databases</button>
                                        </li>
                                    </div>

                                    <hr />
                                    <li className='bg-white py-3'>
                                        <SignOutButton /> {/* Use SignOutButton component for logout */}
                                    </li>

                                </ul>
                            </div>
                        )}
                        {isModalDeleteOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center ml-[-15rem] mr-[-0.5rem] mb-10 justify-center z-50 h-[110vh] overflow-hidden">
                                <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                                    <div className='flex flex-row items-center justify-center mb-3'>
                                        <img src={info} height={'30px'} width={'30px'} className='mx-2' />
                                        <h2 className="text-md font-semibold">Are you sure you want to delete all databases?</h2>
                                    </div>
                                    <hr />
                                    <div className="flex justify-end space-x-4 mt-4">

                                        <button
                                            className="px-4 py-2 bg-[#2E5BFF] rounded text-white hover:bg-[#335fff]"
                                            onClick={handleCloseModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                            onClick={handleConfirmDelete}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
                {/* <ToastContainer /> */}

            </nav>
            <ShowDb isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} databases={databases} setDatabases={setDatabases} />
            {/* <ToastContainer /> */}
            {loader && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
                    {/* <div className="bg-white rounded-full">
                    <img src={Loader} alt="Loader" className="w-40 h-40 rounded-full" />
                    </div> */}
                    <dotlottie-player
                        src="https://lottie.host/1839b101-005a-4014-bb66-2af42bf8a9b1/moiTOzQErw.json"
                        background="transparent"
                        border="2px solid black"
                        speed="1"
                        loop
                        autoplay
                        style={{ width: '40%', height: '40%' }}
                    ></dotlottie-player>
                </div>
            )}
        </body>
    );
};

export default Navbar;
