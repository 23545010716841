import React, { useState } from 'react';
import '../Styles/ContactUsForm.css'; // Add styles for the ContactUsForm
import profile from "../assets/profile.png"; // Path to your profile icon
import email from "../assets/email.png"; // Path to your email icon
import company from "../assets/company.png"; // Path to your company icon
import close from "../assets/close.png"; // Path to your close icon (cross)
import { toast } from 'react-toastify';
 
function ContactUsForm({ onClose }) {

    
    const [formData, setFormData] = useState({
        name: "",
        contact_info: "",
        company_name: "",
        message: "",
    });
 
    // const backendURI = "https://kminernew.azurewebsites.net";
    const [messageStatus, setMessageStatus] = useState("");
 
    // Update state when input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
 
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send-contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
 
            const result = await response.json();
            if (response.ok) {
                setMessageStatus("Message sent successfully!");
                toast.success("Message sent successfully!")
            } else {
                setMessageStatus(result.message || "Failed to send message.");
                toast.error("Failed to send message")
            }
        } catch (error) {
            console.error("Error sending message:", error);
            toast.error("Error sending message:", error)
            setMessageStatus("An error occurred while sending the message.");
        }
    };
 
    return (
        <div className="popup-overlay">
            <div className="popup-form">
                <img src={close} alt="Close" className="close-icon" onClick={onClose} />
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-field">
                        <img src={profile} alt="Profile Icon" />
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-field">
                        <img src={email} alt="Email Icon" />
                        <input
                            type="email"
                            name="contact_info"
                            placeholder="Enter your email address"
                            value={formData.contact_info}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-field">
                        <img src={company} alt="Company Icon" />
                        <input
                            type="text"
                            name="company_name"
                            placeholder="Enter your company (optional)"
                            value={formData.company_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-field">
                        <textarea
                            name="message"
                            placeholder="Enter your message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                            className='w-full rounded-lg border-0 focus:outline-none focus:ring-0 px-4 pt-2 py-1'
                        />
                    </div>
                    <button type="submit" className="submit-btn">Connect</button>
                </form>
                {/* {messageStatus && <p className="status-message">{messageStatus}</p>} */}
            </div>
        </div>
    );
}
 
export default ContactUsForm;
 
 