import React, { useEffect, useRef } from "react";
import "../Styles/Services.css";
import AdvanceAnalytics from "../assets/AdvanceAnalytics.png";
import Multidocument from "../assets/Multidocument.png";
import CustomIntegration from "../assets/CustomIntegration.png";
import ScalabilityPerformance from "../assets/ScalabilityPerformance.png";
 
const Services = () => {
  const sectionRef = useRef(null);
 
  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.8) {
          section.classList.add("animate");
        }
      }
    };
 
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
 
  return (
    <div className="block32" ref={sectionRef}>
      <div className="columns-container">
        <div className="column">
          <img src={AdvanceAnalytics} alt="Advance Analytics" className="iconsimg" />
          <h3 className="column-heading">Advance Analytics</h3>
        </div>
        <div className="column">
          <img src={Multidocument} alt="Multi-Document Querying" className="iconsimg" />
          <h3 className="column-heading">Multi-Document Querying</h3>
        </div>
        <div className="column">
          <img src={CustomIntegration} alt="Seamless Integration" className="iconsimg" />
          <h3 className="column-heading">Seamless Integration</h3>
        </div>
        <div className="column">
          <img src={ScalabilityPerformance} alt="Scalability & Performance" className="iconsimg" />
          <h3 className="column-heading">Scalability & Performance</h3>
        </div>
      </div>
    </div>
  );
};
 
export default Services;
 
 