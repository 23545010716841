import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../caller/axiosConfig';
// import bot from '../assets/bot.gif';
import { IoSend } from "react-icons/io5";
// import loader2 from '../assets/loaderbg2.gif';
import { useNavigate } from 'react-router-dom';
// import profile from '../assets/profile.gif';
// import { MdDelete } from "react-icons/md";
// import dot from '../assets/dot.png'
// import { FaHome } from "react-icons/fa";
import deleteVect from '../assets/deleteVect.png'
import { IoIosArrowBack } from "react-icons/io";
import bot from '../assets/bot.png'
import profile from '../assets/profile.png'
import bgchat from '../assets/bgchat.png'


function Query() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedDb: initialSelectedDb, databases } = location.state || {};
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadinghistory, setloadinghistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [selectedDb, setSelectedDb] = useState(initialSelectedDb || null);
  const [messagesByDb, setMessagesByDb] = useState({});
  const [historyByDb, setHistoryByDb] = useState({});
  const [citationDict, setCitationDict] = useState([]); // New state for citation_dict
  const [citationHistory, setCitationHistory] = useState([]);
  const [showCitations, setShowCitations] = useState({});
  const [dummyState, setDummyState] = useState(0); // Add a dummy state
  const [pdfUrl, setPdfUrl] = useState(null); // For the selected PDF URL
  const [showPdfViewer, setShowPdfViewer] = useState(false); // To control PDF viewer visibility
  const [isBotProcessing, setIsBotProcessing] = useState(false);
  const lastMessageRef = useRef(null);
  const iframeRef = useRef(null);
  const messageEndRef = useRef(null);
  const [initialMessageDisplayed, setInitialMessageDisplayed] = useState(false); // Flag to ensure only 1 initial message
  const handleClose = () => {
    navigate('/home');
  };


  function getUserName() {
    // Retrieve all keys in session storage
    const keys = Object.keys(sessionStorage);

    // Loop through each key to find the relevant data
    for (const key of keys) {
      // Check if the key indicates it may contain user info, particularly the name
      if (key.includes('login.windows.net')) { // or any other relevant keyword
        // Parse the JSON string to get the data object
        const data = JSON.parse(sessionStorage.getItem(key));
        // Check if the data object contains a name field
        if (data && data.name) {
          return data.name; // Return the user's name
        }
      }
    }
    return null; // Return null if no name is found
  }

  // Usage
  const userName = getUserName();
  if (userName) {
    console.log(`User's name: ${userName}`);
  } else {
    console.log("Name not found in session storage.");
  }


  function getUserEmail() {
    const keys = Object.keys(sessionStorage);
    for (const key of keys) {
      if (key.includes('login.windows.net')) {
        const data = JSON.parse(sessionStorage.getItem(key));
        if (data && data.username) {
          return data.username;
        }
      }
    }
    return null;
  }

  // function dotClick() {
  //   navigate("/home")
  // }

  const userEmail = getUserEmail();

  const fetchUserId = async (userEmail) => {
    try {
      const formData = new FormData();
      formData.append('userEmail', userEmail);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch_id`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.user_id;
    } catch (error) {
      throw error.response.data;
    }
  };

  const toggleCitations = (index) => {
    setShowCitations((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the visibility for the specific index
    }));
  };

  const fetchDatabaseHistory = async (database) => {
    setloadinghistory(true); // Start loading
    try {
      const fetchedUserId = await fetchUserId(userEmail);
      const payload = { database, user_id: fetchedUserId };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/chat/history`, payload);
      console.log('historyyy', response)

      if (Array.isArray(response.data)) {
        const allCitations = []; // Array to collect citations as an array of arrays
        response.data.forEach((item) => {
          if (item.answer && item.answer.citation_dict) {
            allCitations.push(item.answer.citation_dict); // Add the citation_dict array to allCitations
          } else {
            allCitations.push([]); // If no citation_dict, add an empty array to maintain the structure
          }
        });
        setCitationHistory(allCitations);
        console.log('All Citations (Array of Arrays):', allCitations);

      } else {
        console.error("The data is not an array or is undefined.");
      }
      // console.log('Citations',citationHistory )

      setHistoryByDb(prevHistory => ({
        ...prevHistory,
        [database]: response.data
      }));

      setHistory(response.data);
    } catch (error) {
      console.error('Error fetching database history:', error);
    } finally {
      setloadinghistory(false); // Stop loading
    }
  };

  useEffect(() => {
    const messageText = userName ? `Welcome ${userName}! How can we assist you today?` : 'Welcome! How can we assist you today?';

    if (!initialMessageDisplayed) {
      // Add initial message from bot if the messages are empty
      setMessages([
        {
          sender: "bot",
          text: messageText,
        },
      ]);
      setInitialMessageDisplayed(true); // Ensure this message is only added once
    }
  }, [initialMessageDisplayed]);

  useEffect(() => {
    console.log('Updated citationHistory:', citationHistory);
  }, [citationHistory]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]); // This effect runs whenever the messages array changes



  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [historyByDb]);

  const handleCitationClick = (citation) => {
    // Set the PDF URL and open the viewer
    setPdfUrl(null);
    setTimeout(() => {
      setPdfUrl(`${citation.file_link}#page=${citation.page_numbers}`);
      setShowPdfViewer(true);
    }, 1000);
  };


  const clearChat = () => {
    console.log('Before clear:', messages, messagesByDb);
    setMessages([]);
    setHistory([]);

    if (selectedDb) {
      setMessagesByDb(prevMessages => ({
        ...prevMessages,
        [selectedDb]: [] // Clear messages for the selected database
      }));
    }
    console.log('After clear:', messages, messagesByDb);
    setDummyState(prev => prev + 1); // Increment dummy state
  };

  const handleDbClick = async (db) => {
    setSelectedDb(db);
    navigate('/query', { state: { selectedDb: db, databases } });
    setMessages(messagesByDb[db] || []);
    await fetchDatabaseHistory(db);
  };

  useEffect(() => {
    if (selectedDb) {
      fetchDatabaseHistory(selectedDb);
    }
  }, [selectedDb]);

  const handleSendMessage = async () => {
    // if (!input) return;
    if (input.trim() === '') return; // Ignore empty messages

    setIsBotProcessing(true);

    const newMessage = { text: input, sender: 'user' };
    setMessages([...messages, newMessage]);
    setMessagesByDb(prevMessages => ({
      ...prevMessages,
      [selectedDb]: [...(prevMessages[selectedDb] || []), newMessage]
    }));
    setInput('');
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/query`, {
        query: input,
        database: selectedDb,
        email: userEmail
      });
      console.log(response)
      const botResponseText = response.data.bot_answer;
      const citt = response.data.citation_dict;
      const botResponse = { text: botResponseText, sender: 'bot', citations: citt };
      // Assuming this is the citation_dict returned
      // console.log('citt', citt);

      setCitationDict(citt);
      console.log('hello', citationDict);
      setMessages(prevMessages => [...prevMessages, botResponse]);

      setMessagesByDb(prevMessages => ({
        ...prevMessages,
        [selectedDb]: [...(prevMessages[selectedDb] || []), botResponse]
      }));

    } catch (error) {
      console.error('Error querying database:', error);
      const errorMessage = { text: 'Please rephrase your query with more details', sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
    setIsBotProcessing(false);

  };

  return (
    <div className="flex h-screen antialiased text-gray-800 bg-white">
      <div className="flex flex-row h-full w-full overflow-x-hidden">
        <div className="flex flex-col py-8 pl-6 pr-2 w-64 flex-shrink-0 bg-[#001335] shadow-lg ">
          <div className="flex flex-row items-center justify-center h-12 w-full">
            <div className="font-bold text-[1.7rem] custom-gradienttext mx-1 px-1">DataDiscover.ai</div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="flex flex-row items-center justify-between text-xs">
              <span className="font-normal text-white">Available databases</span>
              <span className="flex items-center text-white justify-center bg-[#94ABFF] h-5 p-1 w-5 rounded-full">
                {databases ? databases.length : 0}
              </span>
            </div>
            <div className="flex flex-col space-y-1 mt-4 -mx-2 h-auto overflow-y-auto px-2 scrollbar-hidden" style={{ maxHeight: '77vh' }}>
              {databases && databases.map((db, index) => (
                <button
                  key={index}
                  onClick={() => handleDbClick(db)}
                  className={`flex flex-row items-center rounded-3xl p-2 ${selectedDb === db ? 'bg-[#D9D9D945] backdrop-blur-[150px] bg-opacity-60 border border-[#FFFEFE8A]' : 'hover:bg-[#D9D9D945]'}`}

                >
                  <div className="flex items-center text-white justify-center h-8 w-8 bg-[#C8D4FF] rounded-full">
                    {db.charAt(0).toUpperCase()}
                  </div>
                  <div className="ml-2 text-sm text-white font-normal" style={{
                    maxWidth: 'calc(100% - 2rem)', // Keeps the text container from overflowing
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 2, // Limit text to two lines
                  }}>{db}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-auto h-full py-2 px-4">
          <div className='flex justify-between items-center rounded-md py-2 relative'>
            <button
              className="absolute top-4 left-1 text-black  hover:text-gray-800  "
              onClick={handleClose}
            >
              <IoIosArrowBack size={24} />
            </button>
            <div className='text-black px-12'>
              <p className='font-medium	'>{userName}</p>
              <p className='text-sm'>{userEmail}</p>
            </div>
            <div className='flex'>
              {/* <button className='px-4' onClick={dotClick}>
                <FaHome className="w-8 h-8" />
              </button> */}
              <button className='flex items-center p-2 text-white rounded-md' onClick={clearChat}>
                {/* <MdDelete className='mr-1 text-[#DD2025]' /> */}
                <img src={deleteVect} className='w-4 mx-2 h-4 ' />
                <p className='text-[#FD5D76] underline font-medium'>Clear Chat</p>
              </button>
            </div>
          </div>


          {/* Message rendering section */}
          <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl h-[87vh] " 
            style={{
                  backgroundImage: `url(${bgchat})`,
                  backgroundSize:'cover',
                   backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'

      }}
          >
            <div className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                <div className="grid gap-y-2">
                  {loadinghistory ? (
                    <div className="flex justify-center items-center h-full">
                      {/* <img src={loader2} alt="Loading history..." className="w-10 h-10 m-4" /> */}
                      <div class="dots"></div>
                    </div>
                  ) : (
                    history.length > 0 && history.map((msg, index) => (
                      <div key={index} className="flex">
                        <div className="flex flex-col w-full">
                          {msg.user_question && (
                            <div className="flex justify-end">
                              <div className="min-w-[70%] sm:max-w-[70%] col-start-6 col-end-13 p-3 rounded-lg">
                                <div className="flex flex-row-reverse items-center">
                                  <img src={profile} alt="User" className="w-10 h-10 rounded-full" />
                                  <div className="relative mr-3 bg-indigo-100 text-sm py-2 px-4 shadow rounded-xl">
                                    <div>{msg.user_question}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {msg.answer.bot_answer && (
                            <div className="flex justify-start" ref={index === history.length - 1 ? lastMessageRef : null}>
                              <div className="w-fit max-w-full sm:max-w-[70%] col-start-1 col-end-8 p-3 rounded-lg">
                                <div className="flex items-center">
                                  <img src={bot} alt="Bot" className="w-10 h-10 rounded-full" />
                                  <div className="relative ml-3 bg-white text-sm py-2 px-4 shadow rounded-xl">
                                    <div>{msg.answer.bot_answer}</div>

                                    {/* Render citations only for bot's messages */}
                                    {/* {citationHistory && citationHistory.length > 0 && citationHistory[index].length > 0 && (
                                      <div className="mt-2 flex flex-col">
                                        <h1 className='font-bold'>Citations:</h1>
                                        
                                        <ul className="flex flex-wrap">
                                          {citationHistory[index] && citationHistory[index].length > 0 && (
                                            citationHistory[index].slice(0, showCitations[index] ? 3 : citationHistory[index].length).map((citation, citationIndex) => (
                                              <li key={citationIndex}>
                                                <button
                                                  onClick={() => handleCitationClick(citation)} 
                                                  rel="noopener noreferrer"
                                                  className="flex py-1 px-2 mx-1 my-1 bg-[#94ABFF] rounded-full text-white mb-2"
                                                >
                                                  Page {citation.page_numbers} , ({citation.file_name}) pdf
                                                </button>
                                              </li>
                                            ))
                                          )}
                                          {citationHistory[index]?.length > 3 && (
                                            <button
                                              className="mt-2 text-[#2E5BFF] hover:underline flex"
                                              onClick={() => toggleCitations(index)}
                                            >
                                              {!showCitations[index] ? 'Read Less' : ' Read More'}
                                            </button>
                                          )}
                                        </ul>
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )))}

                  {
                    // messages.length === 0 ? (
                    //   // If there are no messages, show the initial bot message
                    //   <div key="initial-message" className="flex justify-start">
                    //     <div className="w-fit min-w-[70%] col-start-1 col-end-8 p-3 rounded-lg">
                    //       <div className="flex items-center">
                    //         <img src={bot} alt="bot gif" className="w-10 h-10 rounded-full" />
                    //         <div className="relative ml-3 bg-white text-sm py-2 px-4 shadow rounded-xl">
                    //           <div>How can I assist you today?</div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // ) : (
                    messages.length > 0 &&
                    messages.map((msg, index) => (
                      <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                        <div className={`w-fit min-w-[70%] col-start-${msg.sender === 'user' ? 6 : 1} col-end-${msg.sender === 'user' ? 13 : 8} p-3 rounded-lg`}>
                          <div className={`flex ${msg.sender === 'user' ? 'flex-row-reverse' : ''} items-center`}>
                            <img
                              src={msg.sender === 'user' ? profile : bot}
                              alt={`${msg.sender} gif`}
                              className="w-11 h-10 rounded-full"
                            />
                            <div className={`relative ${msg.sender === 'user' ? 'mr-3' : 'ml-3'} ${msg.sender === 'user' ? 'bg-indigo-100' : 'bg-white'} text-sm py-2 px-4 shadow rounded-xl`}>
                              <div>{msg.text}</div>

                              {/* Render citations only if present */}
                              {msg.sender === 'bot' && msg.citations && msg.citations.length > 0 && (
                                <div className="mt-2 flex flex-col">
                                  <h2 className="font-bold">Citations:</h2>
                                  <ul className="flex flex-wrap">
                                    {msg.citations.slice(0, showCitations[index] ? msg.citations.length : 3).map((citation, citationIndex) => (
                                      <li key={citationIndex}>
                                        <button
                                          onClick={() => handleCitationClick(citation)}
                                          className="flex py-1 px-2 mx-1 my-1 bg-[#94ABFF] rounded-full text-white mb-2"
                                        >
                                          Page {citation.page_numbers} ({citation.file_name})pdf
                                        </button>
                                      </li>
                                    ))}
                                    {msg.citations.length > 3 && (
                                      <button
                                        className="mt-2 text-[#2E5BFF] hover:underline flex"
                                        onClick={() => toggleCitations(index)}
                                      >
                                        {!showCitations[index] ? 'Read More' : 'Read Less'}
                                      </button>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <div ref={messageEndRef} />
                  {loading && (
                    <div className="flex justify-start">
                      <div className="w-fit min-w-[70%] col-start-1 col-end-8 p-3 rounded-lg">
                        <div className="flex items-center">
                          <img src={bot} alt="Bot" className="w-11 h-10 rounded-full" />
                          <div className="relative ml-3 bg-white text-sm py-3 px-6 shadow rounded-xl">
                            {/* <img src={loader2} alt="Loader" className="w-10 h-10 rounded-full" /> */}
                            <div class="dots"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4" >
              <div className="flex-grow ml-4">
                <div className="relative w-full">
                  <input
                    type="text"
                    className="flex w-full border-[1px] border-[#94ABFF] rounded-full focus:outline-none focus:border-indigo-300 pl-4 h-10 placeholder:text-black"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    placeholder="Write a message"
                    disabled={isBotProcessing} // Disable input when bot is processing

                  />
                </div>
              </div>
              <div className="ml-4">
                <button
                  onClick={handleSendMessage}
                  className="flex items-center justify-center bg-[#2E5BFF] hover:bg-[#335fff] rounded-full text-white px-6 py-2 flex-shrink-0"
                  disabled={isBotProcessing}>
                  <span>Send</span>
                  <IoSend className="ml-2 w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {(showPdfViewer) && (
          <div className="pdf-container flex flex-col items-center justify-center relative mt-4 pr-2">
            <p className='pdf-label absolute'>Loading PDF...</p>
            <button className='py-2 w-full bg-red-500 text-white font-semibold form-control rounded-t-xl' onClick={() => setShowPdfViewer(false)}>Close</button>
            <iframe
              ref={iframeRef}
              src={pdfUrl}
              className='pdf-iframe h-[90%] z-10'
              title='PDF Viewer'
              style={{ width: '500px', border: 'none' }}
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default Query;

