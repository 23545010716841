import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import trail from '../assets/trial.png'
import bgg from "../assets/toolbg.png";
import { IoIosArrowBack } from "react-icons/io";
import invoice1 from '../assets/invoice1.png'
import billinginfo from '../assets/billinginfo.png'
import ContactUsForm from './ContactUsForm';
// import '../Styles/Billing.css'


const Billing = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);


  // const toggleForm = () => {
  //     setShowForm(!showForm);
  // };

  useEffect(() => {
    // Trigger the video animation
    setIsLoaded(true);

    // Start the content animation after the video animation completes
    const contentAnimationTimeout = setTimeout(() => {
      setContentLoaded(true);
    }, 1500); // Match the video animation duration (1.5s)

    return () => clearTimeout(contentAnimationTimeout);
  }, []);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/home');
  };

  const [showForm, setShowForm] = useState(false);


  const toggleForm = () => {
    setShowForm(!showForm);
  };




  return (
    <div className="min-h-screen bg-cover bg-no-repeat z-0 relative overflow-hidden"  style={{ overflow: 'hidden' }}
      // style={{
      //   backgroundImage: `url(${bgg})`,
      //   objectPosition: 'center calc(50%)', // Adjust if precise positioning is needed
      //   backgroundPosition: 'center calc(50% - 37%)',  // Adjusts the vertical positioning
      // }}
      >
        <div className={`herovideo_section3 ${isLoaded ? "zoom-in-animation" : ""} absolute top-0 left-0 w-full h-full overflow-hidden`}>
            <video autoPlay muted loop className="background_video w-full h-full object-cover">
              <source src={`https://aisa0101.blob.core.windows.net/aibot/herobg1.mp4`} type="video/mp4" />
            </video>
          </div>
          <div className='absolute top-0 left-0'>
      <button
        className="absolute top-5 left-5 text-2xl cursor-pointer  text-white  hover:text-gray-800"
        onClick={handleClose}
      >
        <IoIosArrowBack size={24} />
      </button>
      {/* <Navbar/> */}
      <div className={`py-20 overflow-hidden `}>
        <div className={`text-center mb-12`}>
          <p className={`mx-1 font-normal text-white text-md md:text-3xl md:font-semibold lg:text-[2rem] lg:font-semibold xl:text-[2rem] xl:font-semibold xl:mt-4 xl:leading-[1.5]`}>Unleash the Future of Your Business with Advanced AI Empowered by the Innovation of Technology</p>
          {/* <p className={`mx-1 font-normal text-white text-md md:text-3xl md:font-semibold lg:text-[2rem] lg:font-semibold xl:text-[3rem] xl:font-semibold xl:mt-8`}>Empowered by the Innovation of Technology</p> */}
          <p className={`mt-4 md:mt-4 lg:mt-4 text-white text-sm font-normal leading-relaxed mx-auto md:text-sm lg:text-1xl xl:text-[1rem] xl:font-normal xl:mt-2`}>
            that delivers fast, accurate and seamless insights and integration into your existing system.</p>
        </div>

        <div className="flex flex-wrap justify-center md:space-x-6 mb-12 mx-8 gap-2 toolslist ">
          <div className={`bg-[#D9D9D945] backdrop-blur-3xl bg-opacity-50 rounded-3xl shadow-lg p-3 flex flex-col justify-between toolslist1`}>
            <div className="bg-white h-45 w-45 p-4 flex flex-col justify-center items-center rounded-3xl md:h-64 md:w-64 lg:h-64 lg:w-64">
              <h2 className="text-[#2E5BFF] font-semibold text-2xl md:text-3xl">Trial</h2>
              <p className="text-black text-center text-[0.7rem] m-3 md:text-sm">Scans our talent network to create the optimal team for your project.</p>
              <img src={trail} alt="" className="mt-4 w-10 h-10 md:w-20 md:h-20" />
            </div>
          </div>

          {/* <div className="bg-[#D9D9D945] backdrop-blur-3xl bg-opacity-50 rounded-3xl shadow-lg p-4 flex flex-col justify-between">
            <div className="bg-white h-60 w-60 p-4 flex flex-col justify-center items-center rounded-3xl md:h-64 md:w-64 lg:h-64 lg:w-64">
              <h2 className="text-[#2E5BFF] font-semibold text-3xl">Invoices</h2>
              <p className="text-black text-center text-sm m-3 ">Uses data from the past projects to provide better delivery estimates.</p>
              <img src={invoice1} alt="" className="mt-4 w-15 h-20" />
            </div>
          </div> */}

          <div className={`bg-[#D9D9D945] backdrop-blur-3xl bg-opacity-50 rounded-3xl shadow-lg p-3 flex flex-col justify-between toolslist2`}>
            <div className="bg-white h-45 w-45 p-4 flex flex-col justify-center items-center rounded-3xl md:h-64 md:w-64 lg:h-64 lg:w-64">
              <h2 className="text-[#2E5BFF] font-semibold text-2xl md:text-3xl">Invoices</h2>
              <p className="text-black text-center text-[0.7rem] m-3 md:text-sm">Uses data from the past projects to provide better delivery estimates.</p>
              <img src={invoice1} alt="" className="mt-4 w-8 h-10 md:w-[4rem] md:h-20" />
            </div>
          </div>

          <div className="bg-[#D9D9D945] backdrop-blur-3xl bg-opacity-50 rounded-3xl shadow-lg p-3 flex flex-col justify-between toolslist3">
            <div className="bg-white h-50 w-[14rem] sm:w-[20rem] p-4 flex flex-col justify-center items-center rounded-3xl md:h-64 md:w-64 lg:h-64 lg:w-64">
              <h2 className="text-[#2E5BFF] font-semibold text-2xl md:text-3xl">Billing info</h2>
              {/* <p className="text-black text-center text-[0.7rem] m-3 md:text-sm visible:none">Provides the ability to manage your payment and subscription details effectively.</p> */}
              <button className="text-black text-center text-[0.7rem] m-3 md:text-sm" onClick={toggleForm}>Contact Us</button>
              <img src={billinginfo} alt="" className="mt-4 w-10 h-10 md:w-[4rem] md:h-20 lg:w-[5rem]" />
            </div>
          </div>
        </div>

        {showForm && (
          <ContactUsForm onClose={toggleForm} />
        )}
      </div>
      </div>
    </div>

  );
};

export default Billing;
